/**
*
* Jquery Mapael - Dynamic maps jQuery plugin (based on raphael.js)
* Requires jQuery and raphael.js
*
* Map of Romanian counties
* Equirectangular projection

* @source http://ctrl-d.ro/wp-content/uploads/2011/10/index.html
*/
(function (factory) {
    if (typeof exports === 'object') {
        // CommonJS
        module.exports = factory(require('jquery'), require('mapael'));
    } else if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define(['jquery', 'mapael'], factory);
    } else {
        // Browser globals
        factory(jQuery, jQuery.mapael);
    }
}(function ($, Mapael) {

    "use strict";

    $.extend(true, Mapael, {
        maps : {
            romania : {
                width: 600,
                height : 585,
                getCoords : function (lat, lon) {
                    var xfactor = 59.322033898305,
                            xoffset = -1181.8644067797,
                            x = (lon * xfactor) + xoffset,
                            yfactor = -81.720430107527,
                            yoffset = 4043.8279569892,
                            y = (lat * yfactor) + yoffset;
                    return {'x' : x, 'y' : y};
                },
                elems : {
                    "suceava" : "M344.9,184.8c1.7-0.9,3.6-2.4,3.9-3.5c0.3-1.1-1.9-4.5-1.6-5.6c0.3-1.1,4.5,0.1,5.7,2.4c1.2,2.2,4,0.4,7.1,0.5c3,0.1,3.3-1.3,6.3-1.5c3-0.2,11.4-0.5,15.9-2.1c4.6-1.5,4.9,4.6,6.7,4.9c1.8,0.3,3.1-2.1,1.8-3c-1.2-0.9-3.1-6.7-3.1-7.8c0-1.1,2.5-0.5,3.8-0.5c1.3,0,3.4,4.2,5.4,4.3c2,0.1,2.4-5.8,2.4-6.9c0-1.1-2.4-3.9-4.2-4s-0.9-3.6-1.7-3.6s-3.8-9.8-3.7-11.7s-4.1-5.9-5.1-8.4s-6.2-3.5-6.2-5.1c0-1.7-1.2-3.9-3.9-4.8s-5.8-8.4-5.8-8.4c-2.7,1.4-11.9-2.1-13.6-0.7s-6.6,2.6-8.5,3.7s-14.6,0-18.5,0.5c-3.9,0.5-11.6,4.7-11.6,4.7c-2.9,1-3,5.5-4.6,8c-1.6,2.5-8.9,5.1-13.6,4.6c7.6,5.6,3.5,8,1.9,10c-1.6,2,2.4,4.1,4.2,6c1.8,1.9,0.9,4.3,0.9,6.1s-3.5,0.6-2.8,3.6c0.8,3,2.2,7.3,2,13.2c-0.1,5.9,3.6,12.5,6.7,14.8c3.1,2.3,6.5,1.5,8.1-0.2c1.6-1.6,1.4-2.6,4.3-2.8c2.9-0.3,7.1,0.4,8.9,2.1c1.8,1.7,4.5,0.3,8.9,1.1C341.6,194.6,341.3,186.7,344.9,184.8z",
                    "botosani" : "M399.4,166c0-1.1-2.4-3.9-4.2-4s-0.9-3.6-1.7-3.6s-3.8-9.8-3.7-11.7s-4.1-5.9-5.1-8.4s-6.2-3.5-6.2-5.1c0-1.7-1.2-3.9-3.9-4.8s-5.8-8.4-5.8-8.4c2.7-1.4,8.7-18.4,13.6-20.2c4.9-1.7,6,0.3,7.9-0.2c1.9-0.5,6.2-4.5,10.5-3.8c4.4,0.7,11.3,3.6,13.6,6.8c2.3,3.2,7,10.7,8.9,12.1c2,1.3,3.1,9,4.8,9.9c1.7,1,2.6,9.1,4.4,11.2c1.8,2.1-1.8,10.3,1.2,11.9c3.4,1.8,4.3,4.1,5.1,6.4c0,0-6.3-0.2-7.1,2.9c-0.8,3-7.6,0.9-8.8,0.3c-1.2-0.6-2.4,5.2-4.2,7.1c-1.8,1.9-2.4-4.8-4.4-4.8c-2-0.1-10.3-1.6-12.1-0.3C400.5,160.5,399.4,166,399.4,166z",
                    "iasi" : "M388.9,179.9c-1.8,0.3,3.1-2.1,1.8-3c-1.2-0.9-3.1-6.7-3.1-7.8c0-1.1,2.5-0.5,3.8-0.5c1.3,0,3.4,4.2,5.4,4.3c2,0.1,2.4-5.8,2.4-6.9c0,0,1.1-5.5,2.9-6.9c1.8-1.3,10.1,0.3,12.1,0.3c2,0.1,2.6,6.7,4.4,4.8c1.8-1.9,3-7.7,4.2-7.1c1.2,0.6,8,2.7,8.8-0.3c0.8-3,7.1-2.9,7.1-2.9c0.6,1.8,1.2,3.4,2.7,4.7c3.5,2.9,6,3.7,7.2,5.9c1.3,2.3,0.1,7.8,2,9.1c1.8,1.3,8.7,6.7,10,9.1c1.3,2.4,4.8,11.4,7.1,13.3c2.3,1.9,8.8,4.8,10.4,7.5c1.6,2.6,3.3,8,2.6,10.8c0,0-5.5,6-9,2.5c-3.4-3.4-4.3,0.7-6-0.4s-1.9-4.5-1.8-7.8s-4.8,1.3-4.9,2.9c0,1.7-2.9-4-4.7-5.1c-1.7-1.2-2.6,1.6-2.6,4.4s-1.4,3.6-6.6,3.4c-5.3-0.1-7.5-1.9-8.8-0.2c-1.3,1.6-8.1,0.9-8.1,0.9s-2.6-7.3-4.3-7.6c-1.8-0.3,6.4-3.2,6.4-3.2s-1.5-12.2-10.4-8.6c-8.9,3.7-7.7,5.1-9,5.3s-6.4-6.8-9.4-7.2c-3-0.4-6-0.7-6-3.2C395.8,188.1,391.7,179.4,388.9,179.9z",
                    "neamt" : "M428.4,215c0,0-2.6-7.3-4.3-7.6c-1.8-0.3,6.4-3.2,6.4-3.2s-1.5-12.2-10.4-8.6c-8.9,3.7-7.7,5.1-9,5.3s-6.4-6.8-9.4-7.2c-3-0.4-6-0.7-6-3.2c0.1-2.3-6.9-10.7-6.8-10.7c-0.4,0.1-0.4,0-0.3-0.1c-1.5-1-2.1-6.2-6.3-4.7c-4.6,1.5-12.9,1.9-15.9,2.1c-3,0.2-3.3,1.6-6.3,1.5c-3-0.1-5.8,1.8-7.1-0.5c-1.2-2.2-5.5-3.5-5.7-2.4c-0.3,1.1,1.9,4.5,1.6,5.6c-0.3,1.1-2.1,2.6-3.9,3.5c-3.6,1.9-3.4,9.7-3.4,9.7c4.3,0.8,6.7,8.1,7.6,10.4c1,2.2,3.3,1.6,3.1,4.1c-0.2,2.2-2,0.3-5.1,2.3c-1.1,0.7-1.8,3.1,0,5.7c1.8,2.6,1.4,6.1,2.1,10.4c0.7,4.3,4.1,4.7,5.9,5.3c0,0,6.4-4.1,8.9-0.9s8.8,0.6,9-1.2s7.3,0.2,9.3,0.5s8.3,0.3,9.7-1c1.4-1.4,7.5-6.3,10.7-5.1c3.2,1.2,5.6-6,8.2-5.6c2.6,0.3,4.7,3,7.4,3.1c2.6,0.1,5,1.4,6.6,1.4C426.8,224.1,429.4,224.3,428.4,215z",
                    "bacau" : "M383.5,284.9c-0.9-6.1-3.4-12.5-5.5-15.5c-2.1-3-1.6-8.9-3.6-10.6c-2-1.7-2.5,3.8-3.8,3.4c-1.3-0.4-1.8-8.4-5.8-9s-6.4,0.9-5.8-2.2c0.6-2.9,3.1-1.2,3.3-3.6c0.2-2.4,0-8.1-2.2-12.4c-2.2-4.3-3-1.6-4.8-2.2c0,0,6.4-4.1,8.9-0.9s8.8,0.6,9-1.2s7.3,0.2,9.3,0.5s8.3,0.3,9.7-1c1.4-1.4,7.5-6.3,10.7-5.1c3.2,1.2,5.6-6,8.2-5.6s4.7,3,7.4,3.1c2.6,0.1,5,1.4,6.6,1.4c1.1,0,2.5,0.1,3.2-2.3c0,0,0.2,6.6,7.3,6.5c-1.6,4.9-2,7.7-2,7.7s10.3,20.2,10.7,24.4c0.2,1.8-0.5,4.7-0.2,7.5c0.4,3.7,1.6,7.3,1.6,8.1c0,1.4-4.2-4-5-3.2c-0.8,0.8-1.6,5.2-4.1,1.3c-2.4-3.9-8.3-0.5-9.3,0.3c-1,0.8-1.9,4.9-3.2,4.9c-1.3,0-6.1,1-7.4,1.5s-3.1,1.9-3.6,3c-0.5,1.1-8.1-0.2-9.6-1.4c-1.5-1.1-4.7-3.7-7.5-1.3c-2.8,2.4-2.9,5.2-3.9,6.3C391.2,288.3,383.5,284.9,383.5,284.9z",
                    "vaslui" : "M482.5,280c-0.1-3,0.8-11.8,2.8-16.6s1.5-7.2,2.8-9.9c1.2-2.7-0.3-8.9,0.5-13.3c0.8-4.4-2.7-13.2-3.6-15.6c-0.9-2.4-4.7-7.5-3.9-10.3c0,0-5.5,6-9,2.5c-3.4-3.4-4.3,0.7-6-0.4s-1.9-4.5-1.8-7.8s-4.8,1.3-4.9,2.9c0,1.7-2.9-4-4.7-5.1s-2.6,1.6-2.6,4.4s-1.4,3.6-6.6,3.4s-7.5-1.9-8.8-0.2c-1.3,1.6-8.1,0.9-8.1,0.9c0.4,3.4,0.3,5.5-0.1,6.8c0,0,0-0.1,0-0.1c0,0,0.2,6.6,7.3,6.5c-1.6,4.9-2,7.7-2,7.7s10.3,20.2,10.7,24.4c0.2,1.8-0.5,4.7-0.2,7.5c0.4,3.7,1.6,7.3,1.6,8.1c0,0,3.3,7.6,3.9,10.7c0.6,3.2,4.1-0.8,4-3.8c-0.1-3-1.7-6.3-1.2-6.3c0.5,0,7.6,0.6,11.3,3.8c3.8,3.2,2.5-4.6,4.1-4.5C469.7,275.8,482.5,280,482.5,280z",
                    "galati" : "M487.3,334.8c-2.7-2.3-4.7-8.4-4.6-10.1c0-1.7,2.8-3.8,3.7-4.9c0.9-1.1,0.3-11.8-0.5-12.4c-0.8-0.6-9.3-4.1-6.3-4.4c2.6-0.3,2.8-3.8,3.5-5.8c0.7-2-0.5-14.2-0.6-17.2c0,0-12.8-4.2-14.5-4.2c-1.7,0-0.4,7.8-4.1,4.5s-10.8-3.8-11.3-3.8c-0.5,0,1.1,3.4,1.2,6.3c0.1,3-3.4,6.9-4,3.8c-0.6-3.2-4-10.5-4.1-10.5c0,0-2.1,1.1-3.1,3.7c-1.1,2.6-7.6,2.2-8.6,2.7c-1,0.5-4.6,2.8-3.8,5.1c0.8,2.2,1.1,11.1,3,13.4c2,2.3,6,9.9,6.1,11.4c0.1,1.5,3.7,6,5.4,6.4c1.7,0.4,4.5,7.1,5.6,12.9c5.6,5.3,12.8,4.4,14.3,7c1.4,2.6,6.1-0.2,7.7,0.2c1.7,0.4,4.5,2.9,8.6-1.8C482.9,336.8,490.5,337.5,487.3,334.8z",
                    "vrancea" : "M393.2,321.8c2.9,0.1,6.8,3.5,8.1,5.6s9.3,2.2,11.4,1.8c2.2-0.4,6,6.3,6.7,6.3s5.9-0.7,5.4,2.5c-0.6,3.2,3.6,0.5,4.8-0.2s4,0.4,4.5,2.2c0.5,1.8,7,4.3,8.2,1.6c1.2-2.7,8-9.9,8-9.9c-1-5.8-3.9-12.5-5.6-12.9c-1.7-0.4-5.3-4.9-5.4-6.4c-0.1-1.5-4.1-9.2-6.1-11.4c-2-2.3-2.2-11.1-3-13.4c-0.8-2.2,2.8-4.6,3.8-5.1c1-0.5,7.6-0.2,8.6-2.7c1.1-2.6,3.1-3.7,3.1-3.7c-0.8,0-4.1-4.1-4.8-3.4c-0.8,0.8-1.6,5.2-4.1,1.3c-2.4-3.9-8.3-0.5-9.3,0.3c-1,0.8-1.9,4.9-3.2,4.9c-1.3,0-6.1,1-7.4,1.5c-1.3,0.5-3.1,1.9-3.6,3c-0.5,1.1-8.1-0.2-9.6-1.4c-1.5-1.1-4.7-3.7-7.5-1.3c-2.8,2.4-2.9,5.2-3.9,6.3c-1,1.1-8.8-2.3-8.8-2.3c0.9,6.1-1.4,10.5-2.4,14.4s1,10,1.7,11.5C383.1,311.2,390.3,321.7,393.2,321.8z",
                    "buzau" : "M431.8,387.6c-0.1-2.6,2.3-10.5,2.1-11.8c-0.1-1.3-4,0.5-4.3-4c-0.2-4.4-2.6-9.5-2.8-10.1c-0.2-0.6-1.9,2-3.6,1.9c-1.7,0-1.8-2.1-0.6-4.6c1.2-2.6,4.6-3.6,7.3-3.1c2.7,0.4,7,1.8,7.7,0.4c0.7-1.5-0.4-4.3-0.4-4.3c-0.9-4.1,1.4-1.3,1.9-2.9c1.1-3.2,2.7-7.6,2.7-7.6c-1.2,2.7-7.7,0.2-8.2-1.6c-0.5-1.8-3.3-2.9-4.5-2.2s-5.4,3.3-4.8,0.2c0.6-3.2-4.7-2.5-5.4-2.5s-4.5-6.6-6.7-6.3c-2.2,0.4-10.2,0.3-11.4-1.8s-5.2-5.5-8.1-5.6s-10.1-10.6-10.3-11c-0.4,1.7-2.3,2.7-3.7,5.6c-1.2,2.4-0.5,6.3-1.7,7.5s-1.7,1.3-3,0.1c-1.3-1.1-2.6-2.1-3.3-0.8c-0.7,1.3-1.7,2.4-3.3,2.3s-3.8,3.6-3.8,3.6c0.2,0-2.7,4.2-2.5,4.2c2.2,0.2,3.6,0.7,4.3,4.3c0.8,3.9,3.7,5.2,3.5,7.2c-0.2,1.9,0.1,7.1,1.7,7.4c1.6,0.3,1.5,6,3,6.2c1.5,0.2,4.6,2.2,6.1,2s4.9,1.2,5.2,3.7c0.3,2.5,2,5.7,2,5.7s1.5,5.4,2.7,6.3s2.9,4.8,2.6,6.2s0.7,7.5,1.7,8.1c1,0.6,6.9,3.2,10.8,2.6s8.7,0.9,12.8,0.9c4.2,0,6.5,0.4,9.5,2C427.6,395.9,432,390.2,431.8,387.6z",
                    "braila" : "M469.4,395.4c-0.5-1.4-1.1-1.6-3.2-1.3c-2.1,0.2-2.9,1.7-6.3,0.9c-3.4-0.8-6.7-1.1-10.1-0.4c-3.4,0.7-9.3-0.7-12.4,0c-3,0.6-6.8,2.9-9.7,1.3c0,0,4.3-5.7,4.2-8.3c-0.1-2.6,2.3-10.5,2.1-11.8c-0.1-1.3-4,0.5-4.3-4c-0.2-4.4-2.6-9.5-2.8-10.1c-0.2-0.6-1.9,2-3.6,1.9c-1.7,0-1.8-2.1-0.6-4.6c1.2-2.6,4.6-3.6,7.3-3.1c2.7,0.4,7,1.8,7.7,0.4c0.7-1.5-0.4-4.3-0.4-4.3c-0.9-4.1,1.4-1.3,1.9-2.9c1.1-3.2,2.7-7.6,2.7-7.6c1.2-2.7,8-9.9,8-9.9c5.6,5.3,12.8,4.4,14.3,7c1.4,2.6,6.1-0.2,7.7,0.2c1.7,0.4,4.5,2.9,8.6-1.8c0,0-2.1,8.1-2.3,11c-0.2,3,1,5.6,3.1,5.3c2-0.3,4,1.2,3.5,2.9c-0.5,1.6-0.7,3.6,4,6.4c3,1.7-3.6,2.3-3.6,3.6c0,1.3,0.2,6.7,1.5,9.1c1.3,2.4,0.6,9.1-0.6,9.6c-1.2,0.5-2,5.5-3.4,7.7c-1.4,2.2-4.7-0.1-7.2,0S469.4,395.4,469.4,395.4z",
                    "constanta" : "M482.8,392.6c-1.7,1.9-4.7-0.1-7.2,0s-6.3,2.8-6.3,2.8c0.5,1.4,2.2,4.4,5,5.3c2.9,0.9,5.4,6.7,7.2,10.6c1.8,3.9,2.8,5.1,2.3,9.9c-0.5,4.8-0.5,9.3-3,12.4c-2.5,3.1-3.8,4.1-5.4,5.4c-1.7,1.3-5.3-0.1-7.9,1.7c-2.6,1.9-6.1,3.2-7.9,3.1s-5.5-0.3-7.9,2.3s-5.1,4-6.5,4c0,0-7.1,1.5-11.5-0.2c5.6,3.7,5.4,5.9,9.5,8.6c4.1,2.7,12.1,1.4,14.3,0.9c2.2-0.5,5.5,2.2,7.5,3.7c2,1.5,6.9-0.2,9.5-1.6c2.6-1.4,4.8,8.1,7.1,11.1c2.3,3,4.2,6.4,7.4,5.9c3.2-0.5,12.7,4,18.7,4.9c6,0.9,8.2,0.9,10.7-4.5s-2.1-28.2-3.7-28.2s-4.6-4-2.7-7.3s13.3-20.2,16.5-26.4c2.1-4.2,7.8-12.1,12.1-17.5c0,0-12.1-1.6-17.1,0.1c-4.9,1.7-3.4,2.9-4.9,2.8s-6,3.5-8.3,2c-2.3-1.5-3.9-4.4-5.6-4.4c-1.7,0-0.4,3.3-1.8,3.3c-1.3,0-2.5-2.6-3.1-2.7c-0.7,0-1.9,0.3-3-2.7c-1.1-3-3.8-3.8-5.1-3.8c-1.3,0-2.5,0.5-3.3-0.6C487.7,392.5,484.6,390.6,482.8,392.6z",
                    "tulcea" : "M582.6,359.1c-0.1-2.8-2.6-4.7-3.7-6.7s-4.3-9.5-6.9-13.5c-2.6-3.9-9.6-4.3-14.7-5.4c-5-1.1-9.5,3.3-14.1,5.7s-8.2,6.8-11.3,8.2c-3.1,1.4-5.7-1.4-7.2-0.9c-1.5,0.5,0,5-1.2,6.6s-6.6,0.6-10.6-1.2c-4-1.8-13.5-4-15.8-6.9c-2.3-2.8-6.7-8.7-9.8-10.2c3.1,2.7-4.4,1.9-6.6,2.2c0,0-2.1,8.1-2.3,11c-0.2,3,1,5.6,3.1,5.3c2-0.3,4,1.2,3.5,2.9c-0.5,1.6-0.7,3.6,4,6.4c3,1.7-3.6,2.3-3.6,3.6c0,1.3,0.2,6.7,1.5,9.1c1.3,2.4,0.6,9.1-0.6,9.6c-1.2,0.5-2,5.5-3.4,7.7c1.7-2,4.9-0.1,5.7,1.1c0.8,1.1,2,0.6,3.3,0.6c1.3,0,4,0.8,5.1,3.8c1.1,3,2.3,2.6,3,2.7c0.7,0,1.8,2.6,3.1,2.7c1.3,0,0.1-3.3,1.8-3.3c1.7,0,3.3,2.9,5.6,4.4c2.3,1.5,6.8-2,8.3-2s0-1.1,4.9-2.8c4.9-1.7,17.1-0.1,17.1-0.1c0,0,0,0-0.1,0.1c2.1-2.7,4-4.7,4.8-5.3c2.6-1.8,13.9-3.7,18.1-4.3c4.2-0.6,11.2-6,15-9.2C582.4,377.6,582.7,361.8,582.6,359.1z",
                    "mehedinti" : "M177.4,454.5c0.3,1.5-7.7-7-12.2-9.4c-4.5-2.3-9.2-8.9-9.5-11.5c-0.3-2.6-4.2-0.7-5.5-2c-1.3-1.3-6.4-12.4-4.7-14.7c1.7-2.4,4.1-1.4,4.1-1.4s4.1-2.3,4.3-3.4s8.5,1.7,9.1,0.6c0.5-1.1,0.8-6.3-1.2-6.9c-2-0.6-7.4,0-8.4-1.3c-1-1.3-5.4-6.4-8.9-6.3c-3.5,0.1-8.8,3.5-9.4,4.8c-0.5,1.3-3.6,7.7-4.7,8s-5.7,6.3-7.5,5.2c-1.8-1.2-5-7.2-5.3-11.6c0,0,0.5-2,7.2-0.2c3,0.8-0.7-4.8,0.4-8.4s3.4-1.7,4-2.8c0.5-1.1-1.3-5-0.2-5.1c1-0.1,5.4,0.8,6.7,2.5c1.3,1.7,2.6,3.1,4.5,2.3c1.9-0.8,6.4-6.9,8.6-16c2.2-9.1,6.8-13.6,9.7-14c0,0,14.2,8.7,14.9,19.5c0.7,10.8,5.4,16.8,10.1,18c4.8,1.2,19.6,4.7,20.4,10.8s-6.1,2.3-7.9,3.1c-1.8,0.8-7.5,6.2-7.3,9.2s4.2,4.8,2.1,6.4s-8.4,4.5-8.6,9.2c-0.1,4.7,2.8,8.7-0.7,8.6C178.1,447.6,175.7,444.5,177.4,454.5z",
                    "dolj" : "M243.1,481.7c1.9-4.7,5.2-7.1,6.9-13.7s-3.2-4.2-3.8-7.6s3.4-6,3.1-12.7c-0.3-6.7-8.3-12.7-10.7-16.1s-7.4-8-8.1-9.6c-0.7-1.7,0.4-3.9,0.4-7.2c0.1-3.3-2.6-8.4-4.3-8.4c-1.8,0-3.2-13.1-4.9-5.7c-1.7,7.4-3.8,9.3-6.3,7.9s-11.4,2.5-11.4,2.5c0.9,6.1-6.1,2.3-7.9,3.1s-7.5,6.2-7.3,9.2c0.2,3.1,4.2,4.8,2.1,6.4s-8.4,4.5-8.6,9.2s2.8,8.7-0.7,8.6c-3.5-0.1-6-3.2-4.2,6.8c0.5,1.5-1.1,4.2-3.8,4.7c-2.7,0.5-5.5,3.7-6.1,10c-0.7,6.3,4.2,6.8,5.5,7.5c1.3,0.8,12.1,0.1,13.6-0.8c1.5-0.9,9.6-1.6,12.1-1.5c2.5,0.1,9.9,2.3,11.3,3.6c1.5,1.3,5-0.1,7.4-0.4c2.4-0.3,5,2.7,8.3,3.5c2.8,0.7,7.6,4.4,16.1,6.5C241.9,487.8,241.2,486.4,243.1,481.7z",
                    "gorj" : "M224.5,399.7c0.3-3-0.1-16.4,0-20.8s1.2-6.6,3-9.9c1.8-3.3,3-7.1,2.7-8s-1.1-6.4,0-10c1.1-3.6-3.2-4.5-3.2-4.5c-5,0.1-11.1-3.6-11.1-3.6c-0.3,2.3-9.2,3.2-11.7,2.9c-2.5-0.3-5.1,0.7-7.7,1.7c-2.7,1-8,2.8-9.5,2.8c-1.5,0-3.9,1-6,1.2s-7.7-0.9-12.2-2.2c-4.5-1.4-10.2,3.6-12,4c-1.8,0.4-3.5,4.6-3.2,6c0.3,1.4,7.7,3,4.8,3.4c0,0,14.2,8.7,14.9,19.5c0.7,10.8,5.4,16.8,10.1,18c4.8,1.2,19.6,4.7,20.4,10.8c0,0,8.9-3.9,11.4-2.5s4.6-0.4,6.3-7.9C221.7,400.8,224.1,402.8,224.5,399.7z",
                    "valcea" : "M276,323.7c-1.4-1.4-5,4.7-7.2,3.7s-8.4-1.6-11.6-0.7c-3.2,0.9-9.5,5.4-13.1,5.4c-3.7-0.1-6.9-3.1-10.3-3.4c-3.4-0.4-13.6,4.4-15.9,5.7c-2.3,1.3-1.7,6.3-2,8.7c0,0,6.1,3.8,11.1,3.6c0,0,4.3,0.9,3.2,4.5c-1.1,3.6-0.2,9.1,0,10s-0.9,4.7-2.7,8c-1.8,3.3-2.9,5.5-3,9.9s0.3,17.8,0,20.8c-0.1,0.5-0.2,0.9-0.3,1.2c0.8,2.3,1.6,5.5,2.4,5.6c1.8,0,4.4,5.1,4.3,8.4c0,0,1.5,2.3,4.8,1c3.3-1.3,4.3,1.2,5,1.5c0.7,0.3,4.4-3.2,7.4-2.9s4.4-6,5.4-5.4c1,0.6,1.3,6.7,3.9,5.1c2.6-1.6-1.6-7.8-1.5-10.3c0.1-2.5-2.6-8.1,1.9-14.1s6-6.8,5.9-4.3c-0.1,2.5,1.9,4.5,3.6-3.5c1.7-8,2.9-13.8,1.5-19.4c-1.4-5.6-1.2-11.7,0.1-15.5s0-10.5,1.3-12.4C271.6,332.7,276.7,324.4,276,323.7z",
                    "olt" : "M280.9,480.1c0.4-1.7-3.7-0.1-4.3-2.7c-0.6-2.6-2.7-7.5-3.7-11.4s5-4.1,6.1-6.9c1.1-2.7,10.2-4,8.9-6.4c-1.3-2.4-0.9-10.2-0.5-14.2c0.4-4.1,2.4-9.6,2.4-9.6c-0.7-0.6-5.1,0.2-7.2,0.2s-0.6-4.3-1.7-7.1c-1.1-2.8,1.3-6.3,1.4-7.7c0-1.5-2.2-7.4-2.3-8.9c-0.1-1.5,1.4-2.6,1.8-4.9c0.4-2.4-1.7-1.2-6-1.6c-4.4-0.5-4.5-13.1-4.9-13.6c-0.3-0.6-3.8,5.1-5.4,6.1c-1.5,1.1-1.8-5.9-1.8-5.9c0.1-2.5-1.5-1.7-5.9,4.3s-1.8,11.6-1.9,14.1s4.1,8.7,1.5,10.3c-2.6,1.6-2.9-4.5-3.9-5.1s-2.4,5.8-5.4,5.4s-6.6,3.2-7.4,2.9s-1.7-2.8-5-1.5c-3.3,1.3-4.8-1-4.8-1c-0.1,3.3-1.1,5.5-0.4,7.2c0.7,1.7,5.6,6.2,8.1,9.6s10.4,9.4,10.7,16.1c0.3,6.7-3.8,9.3-3.1,12.7c0.7,3.3,5.5,1,3.8,7.6c-1.7,6.6-5,9-6.9,13.7c-1.8,4.5-1.2,6-1.1,6.1c1.8,0.4,3.8,0.8,5.9,1.1c12.2,1.4,13.9-4.1,18.9-4.5c2.9-0.2,6.4,0.3,9.2,1.1L280.9,480.1z",
                    "arges" : "M300.8,427.9c2.8-1,8.4-2.7,10-2.5c1.6,0.2,2.3-3,3-5.1s1.9-4.7-0.5-5.8c-2.4-1.2-3.4-5.2-4.3-7.6c-0.9-2.4,2.9-3.7,3.2-5c0.3-1.4-3.6-1.1-2.6-2.6c1-1.5,0.9-3.7,0.9-9c0-5.3-0.3-17.5-1.6-17.8c-1.3-0.3-0.8-2.9-0.4-3.8c0.4-0.8-0.8-2.5-1.2-7.4c-0.4-4.9,2.9-1.9,4.8-0.4c1.9,1.4,3.8-1.3,4.2-5.6c0.4-4.3,2.2-13.3,2.2-13.3c-1.9,0-3.4-3.4-3.4-3.4s-0.6-4.9-3.5-6.2s-5.8-4.2-7.7-5.6c-1.9-1.4-10.6-1.5-12.4-1.8c-1.9-0.3-4.8-1.2-6.3-1.4s-6.9-0.7-9.2,0c0.7,0.7-4.4,9-5.7,10.9c-1.3,1.9,0,8.6-1.3,12.4s-1.5,9.9-0.1,15.5c1.4,5.6,0.2,11.4-1.5,19.4c-1.6,7.3-3.3,6.3-3.6,4.1c0.1,1.7,0.5,6.1,1.8,5.2c1.5-1.1,5-6.7,5.4-6.1c0.3,0.6,0.5,13.1,4.9,13.6c4.4,0.5,6.4-0.8,6,1.6s-1.9,3.5-1.8,4.9c0.1,1.5,2.3,7.5,2.3,8.9c0,1.5-2.5,4.9-1.4,7.7c1.1,2.8-0.5,7,1.7,7.1c2.2,0.1,6.6-0.8,7.2-0.2C289.7,428.9,298,428.9,300.8,427.9z",
                    "teleorman" : "M339.4,490.6c-3.2,1.4-11.9,6.2-19.6,3.9c-7.7-2.2-10.8-5.6-13.1-5.7s-16.3-1.9-17.6-1.6c-1.4,0.3-5.4,1.3-8.1,0c-1.1-0.6-3-1.3-5.1-1.9c0,0,0.1,0,0.1,0l4.8-5.4c0.4-1.7-3.7-0.1-4.3-2.7c-0.6-2.6-2.7-7.5-3.7-11.4c-1.1-3.9,5-4.1,6.1-6.9c1.1-2.7,10.2-4,8.9-6.4c-1.3-2.4-0.9-10.2-0.5-14.2c0.4-4.1,2.4-9.6,2.4-9.6s8.3,0,11.1-1.1c2.8-1,8.4-2.7,10-2.5c0,0,1.3,2.7,4.4,0s4.1-3.8,4.2-5.1c0-1.3,1.9-1.6,3.1-1.4c1.2,0.2,5,1.8,4.9,3.8c-0.1,2-3.1,2.3-2.8,4.6c0.3,2.2,1.6,8,5.2,8.1c3.5,0.1,9,2.3,9,4.3c-0.1,2,3.8,9.3,2,12.8c-1.8,3.5-7.4,1.3-7.3,4.4c0.1,3.1,0.6,8.5,1.9,11.9c1.3,3.4,1.5,6.1,1.3,9.5C336.3,481.5,339.4,490.6,339.4,490.6z",
                    "dambovita" : "M327.4,422.7c0,0.2,0-0.4-0.1-0.6c-0.6-1.7-3.8-3-4.8-3.2c-1.2-0.2-3,0.1-3.1,1.4c0,1.3-1.1,2.4-4.2,5.1c-3.1,2.7-4.4,0-4.4,0c1.6,0.2,2.3-3,3-5.1s1.9-4.7-0.5-5.8c-2.4-1.2-3.4-5.2-4.3-7.6s2.9-3.7,3.2-5c0.3-1.4-3.6-1.1-2.6-2.6c1-1.5,0.9-3.7,0.9-9s-0.3-17.5-1.6-17.8c-1.3-0.3-0.8-2.9-0.4-3.8c0.4-0.8-0.8-2.5-1.2-7.4s2.9-1.9,4.8-0.4c1.9,1.4,3.8-1.3,4.2-5.6c0.4-4.3,2.2-13.3,2.2-13.3c1.9,0,5.9-2.9,7.9-4.1c0,0,1.4,4.2,1.3,6.7c-0.1,2.5,2.9,6.2,3.8,7.6c1,1.4-1.8,3.3-1.4,5.2s5.4,7.1,6.3,8.2c1,1.1,4.4,6.5,3.3,10.1c-1.1,3.6,4.6,5.9,6.7,6c2,0.1-1.7,6.6-0.7,9.1c0.9,2.5,4.3-1.3,6,1.5c1.7,2.8,1.7,12.2,3.2,14.5c1.5,2.3-0.2,6.1-3.8,9.3c-3.6,3.2-5.6,3.7-6.9,2.9s-4.2-3.4-6.2-2.4c-2,1.1-4.3-1.2-6-0.4C332,416.3,328.9,415.3,327.4,422.7z",
                    "giurgiu" : "M351.2,416.2c0,0-5.6,3.7-6.9,2.9s-4.2-3.4-6.2-2.4c-2,1.1-4.3-1.2-6-0.4c0,0-3.1-1-4.6,6.4c-0.1,2-3.1,2.3-2.8,4.6c0.3,2.2,1.6,8,5.2,8.1c3.5,0.1,9,2.3,9,4.3c-0.1,2,3.8,9.3,2,12.8c-1.8,3.5-7.4,1.3-7.3,4.4c0.1,3.1,0.6,8.5,1.9,11.9c1.3,3.4,1.5,6.1,1.3,9.5s2.9,12.5,2.9,12.5c3.2-1.4,13.6-11.3,15-13.3c1.4-2,8.9-7.7,12.2-10.4c2.4-2,12-6,17.7-7.9c0,0-7.8-0.3-2.6-5.1c5.2-4.9-1.7-11.7-3.2-12.3s-2.5-2.3-4-1.2c-1.5,1.1-4.6,4-6.6,2.1c-2-2-14.6-11.5-17.8-12.9C346.8,428.1,345.5,419.4,351.2,416.2z",
                    "ialomita" : "M480.9,433.5c2.5-3.1,2.5-7.6,3-12.4c0.5-4.8-0.5-6-2.3-9.9c-1.8-3.9-4.3-9.7-7.2-10.6c-2.9-0.9-4.6-3.9-5-5.3s-1.1-1.6-3.2-1.3c-2.1,0.2-2.9,1.7-6.3,0.9c-3.4-0.8-6.7-1.1-10.1-0.4c-3.4,0.7-9.3-0.7-12.4,0c-3,0.6-6.8,2.9-9.7,1.3c-3-1.6-5.4-2.1-9.5-2c-4.2,0-8.9-1.5-12.8-0.9c-3.9,0.6-9.8-2.1-10.8-2.6c0,0-5.3,0.3-6.9,3s-6.4,2.6-7.4,2.6c-1,0-3.4,1.1-5.1,1.5c-0.8,1.6,1.3,9.7,1.2,12.5c-0.1,2.8-0.8,3.6,3.6,5.4c0,0,0,0,0,0c3.2,2.3,6.9-2.9,8.4-1.7c1.5,1.1,5.7,3.7,7.1,0.5c1.3-3.3,5-0.1,7,1.8c2,2,13.6,0.3,15.6,0.4c2,0.1,1.7,2.3,3.4,3.7c1.7,1.4,13.4-0.2,17.9-1.5c4.6-1.3,18,4.9,20.8,5s2.4,4.5,5.3,8.7c2.9,4.2,6.1,7.4,6.1,7.4c0,0-0.1,0-0.1,0c1.6-0.1,3,0,3.9-0.7C477.1,437.6,478.4,436.7,480.9,433.5z",
                    "calarasi" : "M374.5,440.4c0.1,2,2.5,0.6,4,1.2s8.4,7.4,3.2,12.3c-5.2,4.9,2.6,5.1,2.6,5.1c0,0,0,0-0.1,0c1.9-0.6,3.4-1,4.1-1c2.5,0.1,6.9-1.3,9.5-1.8c2.5-0.5,9.2-4.2,9.2-4.2s8.1-1.5,10.7-2.5c2.5-1,16.1,0.4,16.1,0.4c4.4,1.6,11.5,0.2,11.5,0.2c1.4,0,4.2-1.4,6.5-4c2.3-2.6,6.1-2.3,7.9-2.3c1.8,0,5.3-1.3,7.9-3.1c1.2-0.9,2.7-1,4.1-1.1c0,0-3.2-3.2-6.1-7.4c-2.9-4.2-2.6-8.7-5.3-8.7c-2.8-0.1-16.2-6.2-20.8-5c-4.6,1.3-16.2,2.9-17.9,1.5c-1.7-1.4-1.4-3.6-3.4-3.7s-13.7,1.6-15.6-0.4s-5.7-5.1-7-1.8s-5.6,0.7-7.1-0.5c-1.5-1.1-5.1,4-8.4,1.7c2.3,7,3.3,10.9,2.2,12.3c-1,1.4-4.4,3.5-6,5.9C374.6,436,374.4,436.2,374.5,440.4z",
                    "prahova" : "M375.1,397.4c1.7-0.4,4.1-1.6,5.1-1.5c1,0,5.8,0.1,7.4-2.6c1.6-2.7,6.9-3,6.9-3c-1-0.6-2-6.7-1.7-8.1s-1.4-5.3-2.6-6.2s-2.7-6.3-2.7-6.3s-1.7-3.2-2-5.7c-0.3-2.5-3.7-4-5.2-3.7s-4.6-1.8-6.1-2c-1.5-0.2-1.4-5.9-3-6.2c-1.6-0.3-1.9-5.5-1.7-7.4c0.2-1.9-2.7-3.3-3.5-7.2s-5.1-4.1-7.2-4.3c-2.1-0.2-3.4,0.6-4.6,2c-1.2,1.4-3.2,3.2-4,2c-0.7-1.3-3.8-3.3-6-3.9c-2.1-0.6-7.8,0.9-9.6,0.9s-6.1,2.6-8.2,3.8c0,0,1.4,4.2,1.3,6.7c-0.1,2.5,2.9,6.2,3.8,7.6c1,1.4-1.8,3.3-1.4,5.2c0.5,2,5.4,7.1,6.3,8.2c1,1.1,4.4,6.5,3.3,10.1c-1.1,3.6,4.6,5.9,6.7,6s-1.7,6.6-0.7,9.1c0.9,2.5,4.3-1.3,6,1.5c1.1,1.9,1.5,6.7,2.1,10.4c0,0,2.6-5.8,6.1-5.8C363.5,397.1,374.1,397.7,375.1,397.4z",
                    "ilfov" : "M379.9,415.3c-4.5-1.8-3.7-2.6-3.6-5.4c0.1-2.8-2-10.9-1.2-12.5c-1,0.3-11.6-0.3-15.1-0.4c-3.5-0.1-6.1,5.7-6.1,5.8c0.3,1.8,0.6,3.4,1.1,4.1c1.5,2.3-0.2,6.1-3.8,9.3c-0.7,0.6-1.3,1.1-1.9,1.6c-3.3,3.8-2,10.4,0.8,11.7c3.2,1.5,15.9,10.9,17.8,12.9c2,2,5.1-1,6.6-2.1c-0.1-4.2,0.1-4.4,1.7-6.9c1.6-2.5,4.9-4.6,6-5.9C383.2,426.2,382.3,422.3,379.9,415.3z",
                    "satu-mare" : "M223.3,124.6c-1.3-1.7-1.7-4.3-1.7-4.3c-2.9,3-11.6-5.8-13.3-7.4c-1.6-1.5-8.9-5.2-9.9-2.7c-0.9,2.6-6.2,5-7.9,5.3c-1.7,0.3-3.9,0.1-5.3,3.4c-1.4,3.3-4.5,4.3-6,5c-1.5,0.7-3.2,6.9-5.9,8.9c-2.7,2-9.1,0-10.4-0.3c-1.3-0.2-8.9,1.4-10.7,3.2c-1.7,1.8-5.7,4.3-5.7,4.3c-1.7,0.1-3.6,7.3-3.6,7.9c0.1,0.6,5.3,1.2,5.3,3.5c-0.1,2.2-1.7,5.8,0.8,7.2c2.5,1.4,0.7,3.3,2.2,3.7c1.5,0.3,4.3-0.7,6.6-0.1s3,0.9,4,2s0.9,4.5,2.4,4.5c1.5,0,5.4-2.6,7.4-3.7s5.1-1.3,5.1-1.3s1.1,0.7,1.7,0c0.6-0.6,3.2,0.6,3.7,1.8c0.6,1.1,2.7,2.9,3.5,3c0.8,0,4.3,0.8,5.3,1.5c1.1,0.8,2.2-1.4,2.8-1.6c0.6-0.2,1.5-4.5,1.1-5.5c0,0-6.6,0.9-8.1,0c-1.5-1-1.5-2.3-0.9-3c0.5-0.7,3.2-0.8,4.9-1.7c1.7-0.9,1.6-3.8,1.5-4.8c-0.1-0.9,1.5-1.1,2.6-1.8c1-0.7,1.3-3.5,2.4-3.5c1.2,0,4.6,6.2,6.7,6.1c2.2-0.1,0.7-2.8,1.8-4.2c1-1.5,2.8-2.5,3.3-3.2c0.5-0.7,2-0.9,2.1-1.8s-2.4-4.3-5.1-5.3c-2.7-1-3.8-1.6-3.8-2.1c0-0.6,2.1-3.1,4.1-3c2,0.1,6.9,0.9,8.1,0.8c1.2-0.2,5.1-1.3,6.8-2.2c1.7-0.9,2.6-3.3,3.6-3.2c1,0,2.7-1,2.6-2C227.3,127.6,224.8,126.6,223.3,124.6z",
                    "maramures" : "M300.5,150.6c1.6-2,5.7-4.5-1.9-10c-4-1.4-9.9-7.5-12.4-9s-5.7-8.1-11-8.8c-5.4-0.7-18.3,5.1-19.8,3.9c-1.5-1.1-9-5-11.2-4.3c-2.2,0.7-6.5-3.5-8.5-2.8c-2,0.7-4.8-2.3-8.2-2.4c-3.4-0.1-4.2,1.4-6,3.2c0,0,0.4,2.6,1.7,4.3c1.5,1.9,4,2.9,4.1,3.4c0.1,0.9-1.6,2-2.6,2c-1,0-1.9,2.4-3.6,3.2c-1.7,0.9-5.6,2.1-6.8,2.2c-1.2,0.2-6-0.7-8.1-0.8c-2-0.1-4.1,2.5-4.1,3s1.1,1.1,3.8,2.1c2.7,1,5.1,4.4,5.1,5.3s-1.5,1.1-2.1,1.8c-0.5,0.7-2.2,1.8-3.3,3.2c-1,1.5,0.4,4.1-1.8,4.2c-2.2,0.1-5.6-6.1-6.7-6.1c-1.2,0-1.4,2.7-2.4,3.5c-1,0.7-2.7,0.9-2.6,1.8c0.1,0.9,0.2,3.9-1.5,4.8c-1.7,0.9-4.4,1-4.9,1.7c-0.5,0.7-0.6,2,0.9,3c1.5,1,8.1,0,8.1,0s3.4-1,4.5,0.1c1.1,1.1,3.2,0.6,5,1.1c1.8,0.4,2,1.3,3.8,2.1c1.8,0.8,5.8,1.4,7.2,1.5s4.1,2.5,7.4,0.4c3.2-2.1,4.8,1.6,4.8,1.6s1.7,4.5,3.3,4.1c1.5-0.3,3.4-1.2,4.2-1.2c0.8,0,1.4-2,2.7-2s4.3,1.6,5.8,1.4c1.5-0.1,2.7-1.2,3.7-1.8c1-0.5,2.4-2.9,3-4.4c0.5-1.5,0.3-5.7,1.5-5.7c1.2,0,7.6-0.9,8.5-1.8c0.9-0.9,3.7-4.7,5.8-4.8c2.2-0.1,8.3-1.1,11.1-0.1s5,2.5,6,2.6c1,0,2.4-3.5,4-3.4c1.5,0,7.1-1.3,8.8-1.3C297.8,151.7,300.5,150.6,300.5,150.6z",
                    "bihor" : "M164.2,168.9c-0.9-1.4-1.4-3.4-2.4-4.5c-1-1.1-1.7-1.4-4-2c-2.3-0.6-5.1,0.4-6.6,0.1c-1.5-0.3,0.3-2.2-2.2-3.7c-2.5-1.4-0.9-5-0.8-7.2c0.1-2.2-5.2-2.9-5.3-3.5c-1.2,1.1-9.8,6.8-11.6,8.2c-1.9,1.4,1.5,7.1-1.9,8.5c-3.4,1.4-8.5,15.9-8.9,18.6s-9,8.8-10.8,9.9c-1.9,1.1,1.1,8.9-0.7,9s-2.2,8.1-4.2,8.4c-2,0.3-6.3,8.2-6.3,9.6c0,1.5,1.7,6.3-0.9,7c1.4,3.6,4.3,2.1,4.7,2.9c0.4,0.8,3.1-2,4-2.1c0.9-0.1,6.8,1,8.4,0.6s2-2.4,3-2.4c1,0,3.2,2.6,4.6,2.6s7.7-1.2,9.7,0.9c2,2.1,2.9,3.1,3.3,3.1c0.4,0,1.3,4.3,1.4,5.3c0.1,1,3.6,4,5.6,4.2c2,0.2,4.8,3.4,6.5,5.7c1.7,2.3,7.5-0.6,10.3,2.8c0.4,0.5,3.1,0.6,5.4,0.8c0,0,0.8-2.3,2-3.6s-1.5-3.6-1.5-5.2c0-1.5,1.1-5.4,3.6-6.4c2.4-1,3.8,0,4.1-1.3s1.4-5.1,0.8-5.5s-3.5,0.6-3.5-0.2c0-0.8,1.2-6.1-0.8-7.5c-2-1.4-2.2-2.8-3.2-4.1c-1-1.3-0.5-1.7-0.5-1.7s4.5,0.3,5.1-0.7c0.5-1,0.7-4,0.7-4s2.6-2.7,1.9-3.8c-0.7-1.1-4.1-2-4.9-3.9c-0.8-1.8,1.7-3.1,1.8-4.7c0-1.5-1.7-5.9-4.9-5.4s-3.9-1.8-3.8-2.6c0-0.8,0-6.5-1.4-6.4s-2,0.5-2-1s1.7-2.5,2.5-3.1c0.8-0.7,1.3-6.3,1.6-7.3C162,172.5,165.7,171.2,164.2,168.9z",
                    "arad" : "M53.4,272.2c0.2,1.8,4.1,3.4,4.8,3.4c0.8,0,1.3,1.6,1.6,2.4c0.2,0.8,4.8,0.8,6.7,1.3c1.9,0.5,2.6,3.4,3.3,4.4c0.7,1,2.2-1.6,3-2s1.2,0.6,2.5,1.9c1.2,1.3,2.7,1.5,3.8,0.1c1-1.4,2.9-1.5,3.4-0.3c0.5,1.1,2.7,1,3.9,0.9c1.1-0.1,2.3-2.7,2.4-3.7s3.6-2.1,5.1-2.2c1.5-0.1,1.2,3.5,1.2,3.5c0.2,1.8,3.4,3.4,5.4,4.7s0.9,5,3.4,4.9c2.5-0.1,2.8-4.9,4-4.9c1.3,0,0.4,5.4,1.5,6c1.1,0.6,2.6-2.4,4.5-2.4c1.9,0,3.8-2.5,4.6-3.5c0.8-1,2.8,0.6,4,0.7c1.3,0,5.3,0,6.8,0.3c1.5,0.3,4.4,4.3,5.7,4.3c1.3,0,3.3-0.9,5.5-1.7c2.2-0.8,4.7-2.2,5.9-3.6c1.2-1.4,5.1-2.5,5-3.8c-0.1-1.3-0.8-4-0.8-5c0-1,2.2-2,1.7-3.7c-0.5-1.7-2.5-6.4-1.6-8.1c0.9-1.6,2.2-1.3,3.9-1.3s6.9,0,8.1,0.1c1.1,0,0.7-3.4,1.9-4.9c1.2-1.5,3.5-3.9,3.5-3.9s0.4-1.7-0.7-1.7c-1.1,0-3-2.6-3-2.6c-2.3-0.2-5-0.3-5.4-0.8c-2.8-3.4-8.6-0.5-10.3-2.8c-1.7-2.3-4.5-5.5-6.5-5.7c-2-0.2-5.5-3.2-5.6-4.2c-0.1-1-1-5.3-1.4-5.3c-0.4,0-1.4-1-3.3-3.1s-8.3-0.9-9.7-0.9s-3.6-2.6-4.6-2.6s-1.3,2-3,2.4c-1.6,0.4-7.5-0.7-8.4-0.6c-0.9,0.1-3.6,3-4,2.1c-0.4-0.8-3.3,0.7-4.7-2.9c-2.5,0.7-6.4,0.8-8.5,2.6c-2.1,1.8-1.9,7-1.9,7.9c0,0.9-4.7,1-4.8,3.2s5.5,0.9,3.7,5.3c-1.8,4.4-6.3,3.4-8.4,5.1c-2.1,1.8-3.4,8.8-5.8,10c-2.4,1.2-12.5-3.5-17.3-1.2s-7.5,9.5-5.5,9.5C50.9,269.8,53.2,270.3,53.4,272.2z",
                    "timis" : "M91.3,350.1c0.4-1.8,1.5-2.6,1.5-3.6c0-1-1-6.5-3.2-7.6c-2.2-1-0.7-3.8-0.6-5.3c0-1.5-0.3-4.4,0.1-5.1c0.4-0.7,3.8-0.7,5.4-0.7c1.6,0,1-4.8,1.4-5.6c0.4-0.8,3-0.8,4.7-1.3s2,1,3.4,1.9c1.4,0.9,4.3,2.6,5.5,3.2c1.1,0.6,5.6-0.4,6.8-0.4s6.3,3.6,7.5,3.7c1.1,0,0.8-2.1,0.1-3.6s-2-4.2-0.9-4c1.1,0.2,3.8-0.7,5-1.5s0.5-3,2-3.4c1.5-0.4,2,1,3.2,3c1.2,2,2.8,0.9,2.8,0.9c2.7-0.1,2-4.2,3.7-4.9c1.7-0.7,5.7-0.4,6.8-0.4c1.1,0,2.8-2.4,3.3-4.5s5-3.5,5.1-5.4s-5.8,0.1-4.9-1.1c0.9-1.2,1.9-5.1,0.6-5.3c-1.3-0.2-2.5-2.7-2.4-4.2c0-1.5,0.9-3.9,0.9-5.9s-2.6-2.1-2.6-2.1c-1.2,1.4-3.7,2.8-5.9,3.6c-2.2,0.8-4.2,1.7-5.5,1.7c-1.3,0-4.2-4-5.7-4.3c-1.5-0.3-5.5-0.3-6.8-0.3c-1.3,0-3.2-1.6-4-0.7c-0.8,1-2.7,3.5-4.6,3.5c-1.9,0-3.4,3-4.5,2.4c-1.1-0.6-0.2-6-1.5-6c-1.3,0-1.5,4.8-4,4.9c-2.5,0.1-1.4-3.6-3.4-4.9s-5.2-2.9-5.4-4.7c0,0,0.3-3.6-1.2-3.5c-1.5,0.1-5.1,1.3-5.1,2.2s-1.2,3.6-2.4,3.7c-1.1,0.1-3.4,0.2-3.9-0.9c-0.5-1.1-2.4-1-3.4,0.3c-1,1.4-2.6,1.2-3.8-0.1c-1.2-1.3-1.7-2.3-2.5-1.9s-2.2,3-3,2c-0.7-1-1.4-3.9-3.3-4.4c-1.9-0.5-6.4-0.4-6.7-1.3c-0.2-0.8-0.8-2.4-1.6-2.4c-0.8,0-4.6-1.6-4.8-3.4c-0.2-1.8-2.5-2.4-4.1-2.4c-1.5,1.4-7.3-3-8.3-3.7s-5.8,1.5-7.1,2c-1.4,0.5-6.5-1.3-7.7-1.3s-4.1,3-3.6,4.5c0.5,1.5,3.7,8.2,4.8,8.8s5.6,2.7,6.1,4.6c0.5,1.9,3.3,4.1,4.3,4.2c1,0,5.8,9.6,6.9,11.5c1.1,1.9,5.4,0.5,6.7,1.3c1.3,0.8-0.3,10.5-1.5,11.6c-1.2,1.1,1.5,5.8,1.5,5.8c0.6,1.5-1.9,3.6-1.7,6.2c0.3,2.6,1.8,0.6,2.8,3.6c0.9,3,8.6,11.1,10.1,11.2s6,1.1,6.2,2.7c0.1,1.7,6.6,3.9,8,3.9c1.3,0,13.1,6.6,13.6,9.2C90.3,355.8,90.9,351.8,91.3,350.1z",
                    "caras-severin" : "M161.1,351.1c-1.9,1-3.4,2.1-4.2,2.2c-1.8,0.4-3.5,4.6-3.2,6c0.3,1.4,7.7,3,4.8,3.4c-2.9,0.5-7.4,4.9-9.7,14c-2.2,9.1-6.7,15.2-8.6,16c-1.9,0.8-3.1-0.6-4.5-2.3c-1.3-1.7-5.7-2.6-6.7-2.5c-1,0.1,0.8,4,0.2,5.1c-0.5,1.1-2.9-0.9-4,2.8c-1.1,3.7,2.6,9.3-0.4,8.4c-6.7-1.8-7.2,0.2-7.2,0.2c-0.2-4.4-1.9-4.1-3.6-4.2c-1.7,0-12.7-1.6-14.6-3c-1.8-1.3-4.9-0.5-5.8-1.6c-1-1.1-5.7-6.4-6.7-6.5s-3-2.8-4-2.1c-1,0.7-3.4-4.7-1.5-5.4s6.6-0.2,6.8-1.5c0.2-1.3,2.5-6,1-6.4c-1.5-0.4-7.1-5.9-6.7-6.8s5.5-3,5.7-4.7c0.2-1.7,2.6-4,2.2-6.6c0,0,0.6-4,1-5.8c0.4-1.8,1.5-2.6,1.5-3.6c0-1-1-6.5-3.2-7.6c-2.2-1-0.7-3.8-0.6-5.3c0-1.5-0.3-4.4,0.1-5.1c0.4-0.7,3.8-0.7,5.4-0.7c1.6,0,1-4.8,1.4-5.6c0.4-0.8,3-0.8,4.7-1.3s2,1,3.4,1.9c1.4,0.9,4.3,2.6,5.5,3.2c1.1,0.6,5.6-0.4,6.8-0.4s6.3,3.6,7.5,3.7c1.1,0,0.8-2.1,0.1-3.6s-2-4.2-0.9-4c1.1,0.2,3.8-0.7,5-1.5s0.5-3,2-3.4c1.5-0.4,2,1,3.2,3c1.2,2,2.8,0.9,2.8,0.9c2.7-0.1,2-4.2,3.7-4.9c1.7-0.7,5.7-0.4,6.8-0.4c0,0,1.6,3.1,2.9,3.4c1.4,0.3,2.7,1.2,2.7,3s3.1,2.7,4,2.5c0.9-0.3,2.6,2.4,2.7,3.1c0.1,0.7,3.8,9.4,2.2,13.2c-1.6,3.8,0.4,7.4,0.5,8.2C161.4,349.5,161.1,351.1,161.1,351.1z",
                    "hunedoara" : "M164.4,251.8c0,0,1.8,2.5,3,2.6c1.1,0,0.7,1.7,0.7,1.7s-2.3,2.4-3.5,3.9c-1.2,1.5-0.8,5-1.9,4.9c-1.1,0-6.3,0-8.1-0.1s-3-0.4-3.9,1.3c-0.9,1.6,1.1,6.4,1.6,8.1c0.5,1.7-1.7,2.7-1.7,3.7c0,1,0.7,3.8,0.8,5c0.1,1.3-3.8,2.4-5,3.8c0,0,2.6,0.1,2.6,2.1s-0.9,4.4-0.9,5.9c0,1.5,1.2,4,2.4,4.2c1.3,0.2,0.3,4-0.6,5.3c-0.9,1.2,4.9-0.8,4.9,1.1s-4.6,3.3-5.1,5.4s-2.1,4.5-3.3,4.5c0,0,1.6,3.1,2.9,3.4c1.4,0.3,2.7,1.2,2.7,3s3.1,2.7,4,2.5c0.9-0.3,2.6,2.4,2.7,3.1c0.1,0.7,3.8,9.4,2.2,13.2c-1.6,3.8,0.4,7.4,0.5,8.2c0.1,0.8-0.2,2.5-0.2,2.5c-0.3,0.1-0.5,0.3-0.8,0.4c2.5-1.4,5.7-3,8.6-2.2c4.5,1.4,10.2,2.5,12.2,2.2c2-0.2,4.4-1.3,6-1.2c1.5,0,6.9-1.8,9.5-2.8c2.7-1,5.2-2.1,7.7-1.7c2.5,0.3,11.4-0.5,11.7-2.9c0.3-2.3-0.3-7.4,2-8.7c0,0-3.9-1.9-5.2-7.1c-1.2-5.2-4.3-1.6-5.8-3.7c-1.5-2.1-1.9-10.2-3.8-15.6c-1.9-5.4-2.3-8.4-2.2-11.1c0.1-2.8-2.5-7.1-3.1-10.4c-0.6-3.3-6.2-1.6-6.9-4.6c-0.8-3-2.1-4.3-3.4-4.5c-1.3-0.2,0.9-3.9,3.6-2.9c2.7,1-2.2-6.2-6-7.5c0,0-4.7-0.5-4.8-3.1s0.7-7.2-3.2-7.3C173.2,256.3,166.7,254.8,164.4,251.8z",
                    "alba" : "M172.5,235.4c-0.3,1.2-1.6,0.2-4.1,1.3c-2.4,1-3.5,4.9-3.6,6.4c0,1.5,2.7,3.9,1.5,5.2c-1.2,1.2-2,3.6-2,3.6c2.3,3,8.8,4.5,12.7,4.6c3.9,0.1,3.1,4.7,3.2,7.3s4.8,3.1,4.8,3.1c3.8,1.4,8.7,8.5,6,7.5c-2.7-1-4.9,2.6-3.6,2.9c1.3,0.2,2.7,1.5,3.4,4.5s6.4,1.3,6.9,4.6c0.6,3.3,3.2,7.7,3.1,10.4s0.4,5.7,2.2,11.1c1.9,5.4,2.3,13.6,3.8,15.6c1.5,2.1,4.6-1.5,5.8,3.7c1.2,5.2,5.2,7.1,5.2,7.1s-0.3-6.5,0.3-8c0.7-1.5-0.7-6.7-1.3-9.3c-0.6-2.6,1.8-5.2,2.4-5.9c0.6-0.7-2.5-2.1-2.1-3.7c0.4-1.5,4.7-0.4,5.6-1.8c0.9-1.4,0.4-6.5,1.4-8.6s6-2.1,7.4-3.4c1.4-1.4-0.9-5,1.6-6.5c2.4-1.5,5.1,1.4,6.8,1.3c1.6-0.1,2.2-6.5,4.4-8.3c2.2-1.9,6.9-2,8.7-4.9c1.8-2.9,0.2-4.3,0.5-4.8c0.3-0.5-0.1-6.9-8.9-11.2c-8.8-4.2-1.4-5.3-1.5-5.7c-0.1-0.4-6.3-6.4-12.8-6.6c-6.6-0.2-6.8,5.2-10.6,4.4c-3.8-0.8-1.5-4.5-0.8-4.7c0.8-0.3-2.5-2.4-5.1-2.5c-2.6-0.1-4.3-0.7-6.2-2.4c-2-1.7-5.9-2.4-8-1.5c-2.2,0.9-2.3,4.9-4.4,3.5c-2.1-1.4-5.2,0.4-7.1,1.8c-1.9,1.3-3.9-4-4.4-6.2c-0.4-2.2-4.9-1.5-6.8-0.7C175.1,239.3,172.5,235.4,172.5,235.4z",
                    "sibiu" : "M253.6,270.3c0,0.6,1.3,2-0.5,4.8c-1.8,2.9-6.5,3-8.7,4.9c-2.2,1.9-2.7,8.2-4.4,8.3c-1.6,0.1-4.3-2.7-6.8-1.3c-2.4,1.5-0.1,5.1-1.6,6.5c-1.4,1.4-6.5,1.4-7.4,3.4s-0.4,7.2-1.4,8.6c-0.9,1.4-5.2,0.3-5.6,1.8c-0.4,1.5,2.7,3,2.1,3.7c-0.6,0.7-3,3.3-2.4,5.9c0.6,2.6,1.9,7.8,1.3,9.3c-0.7,1.5-0.3,8-0.3,8c2.3-1.3,12.5-6.1,15.9-5.7c3.4,0.4,6.6,3.4,10.3,3.4c3.7,0.1,9.9-4.5,13.1-5.4c3.2-0.9,9.5-0.3,11.6,0.7s4.9-2.9,7.2-3.7c1.3-0.4,3.7-0.5,5.7-0.3c0,0-0.7-10.3-1.9-11.1s-1.7-6.3,2.7-6.7c4.3-0.4,2.9-2.8,2.6-3.6s-6.6-2.5,0.9-7.8c7.4-5.3,11-7.6,11.2-9.4s-4.7-4.1-8.1-3.3c-3.3,0.8-7.1,3.3-7.8,1.1c-0.8-2.2,0.1-16.3,0.1-16.3s-6.8-0.2-9,1.4c-2.2,1.6-6.8,0.4-8.1-0.2C262.8,266.9,253.7,267.2,253.6,270.3z",
                    "brasov" : "M295.2,282.5C295.3,282.5,295.3,282.5,295.2,282.5c1.2,0.7,1.9,1.5,1.9,2.2c-0.2,1.8-3.8,4.1-11.2,9.4c-7.4,5.3-1.2,7.1-0.9,7.8s1.7,3.1-2.6,3.6c-4.3,0.4-3.8,5.9-2.7,6.7s1.9,11.1,1.9,11.1c0,0,0,0,0,0c1.5,0.1,2.9,0.2,3.5,0.3c1.5,0.2,4.4,1.1,6.3,1.4c1.9,0.3,10.6,0.4,12.4,1.8c1.9,1.4,4.8,4.3,7.7,5.6s3.5,6.2,3.5,6.2s1.6,3.4,3.4,3.4c1.9,0,5.9-2.9,7.9-4.1c2-1.2,6.4-3.9,8.2-3.8s7.5-1.5,9.6-0.9c2.1,0.6,5.2,2.6,6,3.9c0.7,1.3,2.8-0.6,4-2c1.2-1.4,2.5-2.2,4.6-2l2.9,0.1c0,0,2.6-4.2,2.5-4.2c0,0-5.4-8.1-7.2-11.5c-1.8-3.4-7.5-5.7-9.4-5.8s-7.3,0.6-8.7-0.8s-6.8-6.8-5.6-8.5c1.2-1.6,3.6-2.7,3.4-3.9c-0.2-1.3-5.8-5.6-4.8-9.7c1-4.1-2.1-2.3-4.7-4.7c-2.6-2.4-1.6-5.6-3.5-5.6c-1.9,0-7.1-3.2-10.3-1.8s-4.3,2.1-6.7,0.9C301.5,275.5,295.2,282.5,295.2,282.5z",
                    "covasna" : "M374.9,266c0,1.9-2.2,7.7-4.5,7.7c-2.3-0.1-8.6,2.8-10.2,3.9s-9.6-1.4-11.6-1.4s-5.5-13.4-5.5-13.4s-2.6,2.4-4.3,2.4c-1.8,0-8.2,3.7-8.8,8.9c-0.6,5.3-6.6,4.7-6.6,4.7c1.9,0,0.9,3.2,3.5,5.6c2.6,2.4,5.7,0.6,4.7,4.7c-1,4.1,4.6,8.4,4.8,9.7c0.2,1.3-2.2,2.3-3.4,3.9c-1.2,1.6,4.2,7,5.6,8.5s6.8,0.7,8.7,0.8s7.6,2.4,9.4,5.8c1.8,3.4,7.2,11.5,7.2,11.5s2.3-3.6,3.8-3.6s2.5-1,3.3-2.3c0.7-1.3,2-0.3,3.3,0.8c1.3,1.1,1.8,1.2,3-0.1s0.5-5.1,1.7-7.5c1.4-2.9,3.3-4,3.7-5.6c0.4-1.7-2.7-7.6-1.7-11.5s3.2-8.2,2.4-14.4s-3.4-12.5-5.5-15.5",
                    "harghita" : "M313.2,276.9c3.4,0.6,8.4,1.7,10.3,1.8c0,0,5.9,0.6,6.6-4.7c0.6-5.3,7-9,8.8-8.9c1.8,0,4.3-2.4,4.3-2.4s3.4,13.4,5.5,13.4s10,2.5,11.6,1.4s7.9-4,10.2-3.9c2.3,0.1,4.4-5.7,4.5-7.7l3.1,3.4c-2.1-3-1.6-8.9-3.6-10.6c-2-1.7-2.5,3.8-3.8,3.4c-1.3-0.4-1.8-8.4-5.8-9s-6.4,0.9-5.8-2.2c0.6-2.9,3.1-1.2,3.3-3.6c0.2-2.4,0-8.1-2.2-12.4c-2.2-4.3-3-1.6-4.8-2.2c-1.8-0.6-5.2-1.1-5.9-5.3c-0.7-4.3-0.3-7.8-2.1-10.4c-1.8-2.6-1.1-5.1,0-5.7c3.1-2,4.9-0.1,5.1-2.3c0.2-2.6-2.1-1.9-3.1-4.1c-1-2.2-3.3-9.5-7.6-10.4c-4.3-0.8-7.1,0.6-8.9-1.1c-1.8-1.7-6-2.4-8.9-2.1c-2.9,0.3-2.7,1.2-4.3,2.8c-0.9,1-2.6,1.7-4.4,1.6c0,0,3.7,7.3,3.1,11.3c-0.6,4.1-2.9,7.1,0.3,12.8c3.2,5.6-3.5,18.4-6.7,20.5c-3.2,2.1-11.6,6-12.7,10.2c-1.1,4.2-1.2,6.1-2,7c-0.9,0.9-4.1,0.6-4.5,3.4c-0.4,2.8,1.9,2.8,2.4,4.5s6.2,2.7,9.3,4.1C307.5,271,307.1,275.9,313.2,276.9z",
                    "mures" : "M243.2,253.4c0.2,0.4-7.4,1.5,1.5,5.7c8.2,4,9.1,9.7,9,11c0.5-2.9,9.2-3.2,10.5-2.6c1.3,0.6,5.9,1.8,8.1,0.2c2.2-1.6,9-1.4,9-1.4s-0.9,14-0.1,16.3c0.8,2.2,4.5-0.3,7.8-1.1c2.1-0.5,4.7,0.2,6.4,1.2c0,0-0.1-0.1-0.1-0.1c0,0,0,0-0.1,0c0,0,6.3-7,11.3-4.6c2.5,1.2,3.5,0.5,6.7-0.9c-6.1-1-5.7-6-8.9-7.3c-3.2-1.4-8.8-2.4-9.3-4.1c-0.5-1.7-2.8-1.7-2.4-4.5c0.4-2.8,3.6-2.5,4.5-3.4c0.9-0.9,0.9-2.7,2-7c1.1-4.2,9.5-8.1,12.7-10.2c3.2-2.1,10-14.9,6.7-20.5c-3.2-5.6-1-8.7-0.3-12.8c0.6-4.1-3.1-11.3-3.1-11.3c0.1,0,0.1,0,0.1,0c-1.2-0.1-2.5-0.5-3.8-1.4c0,0-4.2-1.2-4.7-1.9c0,0-8.8,3.1-10.9,3.1c-2.1-0.1-5.6,6-6.9,7.5c-1.3,1.5-3.3,5.7-2.1,8.4c1.2,2.7-1.3,2.5-1.3,2.5s-3.2-4.8-5.2-5.3c-2-0.5-3.8,2.5-4,3.5c-0.2,1-2.4,4.7-3.9,5.9c-1.5,1.2-1.1,3.9-3.2,5s-5.9,2.3-9.1,1.3s-6-3.8-6.8-3.5c-0.9,0.3-6.1,3.3-6.2,4.6c0,1.2,3.4,3.8,2.3,5.6c-1.2,1.8-5.5,1.5-5.5,4c-0.1,2.5,1.8,7,0.1,7.9C242.2,243.8,237.5,244.5,243.2,253.4z",
                    "bistrita-nasaud" : "M253.3,220.8c0.9-0.3,3.6,2.4,6.8,3.5s7.1-0.1,9.1-1.3c2-1.2,1.6-3.8,3.2-5c1.5-1.2,3.8-4.9,3.9-5.9c0.2-1,2-4,4-3.5c2,0.5,5.2,5.3,5.2,5.3s2.5,0.2,1.3-2.5c-1.2-2.7,0.8-6.9,2.1-8.4c1.3-1.5,4.7-7.5,6.9-7.5c2.1,0.1,10.9-3.1,10.9-3.1c0.5,0.7,4.7,1.9,4.7,1.9c-3.1-2.3-6.8-8.9-6.7-14.8s-1.3-10.2-2-13.2c-0.8-3,2.7-1.8,2.8-3.6c0-1.8,0.9-4.2-0.9-6.1c-1.8-1.9-5.8-4-4.2-6c0,0-2.7,1-4.4,1c-1.7,0-7.3,1.3-8.8,1.3s-2.9,3.4-4,3.4c-1,0-3.2-1.6-6-2.6s-8.9,0-11.1,0.1c-2.2,0.1-5,3.9-5.8,4.8c-0.9,0.9-7.3,1.8-8.5,1.8c-1.2,0-0.9,4.2-1.5,5.7s-1.9,3.8-3,4.4s-2.2,1.6-3.7,1.8c0,0,2.7,5.1,1.7,5.6c-1,0.5-6.1,2.6-4.2,6.5c1.9,3.9,9.9,7.5,10.6,10.5c0.7,3.1-2.7,7.4-1.2,9.4s8.5,4.9,2.9,15.6",
                    "cluj" : "M235.2,172.8c0.8,0,1.4-2,2.7-2c1.3,0,4.3,1.6,5.8,1.4c0,0,2.7,5.1,1.7,5.6c-1,0.5-6.1,2.6-4.2,6.5c1.9,3.9,9.9,7.5,10.6,10.5c0.7,3.1-2.7,7.4-1.2,9.4s8.5,4.9,2.9,15.6l-0.1,0.8c-0.9,0.3-6.1,3.3-6.2,4.6c0,1.2,3.4,3.8,2.3,5.6c-1.2,1.8-5.5,1.5-5.5,4c-0.1,2.5,1.8,7,0.1,7.9c-1.8,0.9-6.5,1.6-0.8,10.5c-0.1-0.4-6.3-6.4-12.8-6.6c-6.6-0.2-6.8,5.2-10.6,4.4c-3.8-0.8-1.5-4.5-0.8-4.7c0.8-0.3-2.5-2.4-5.1-2.5c-2.6-0.1-4.3-0.7-6.2-2.4c-2-1.7-5.9-2.4-8-1.5c-2.2,0.9-2.3,4.9-4.4,3.5s-5.2,0.4-7.1,1.8c-1.9,1.3-3.9-4-4.4-6.2c-0.4-2.2-4.9-1.5-6.8-0.7c-1.9,0.8-4.5-3.2-4.5-3.2c0.3-1.2,1.4-5.1,0.8-5.5s-3.5,0.6-3.5-0.2c0-0.8,1.2-6.1-0.8-7.5c-2-1.4-2.2-2.8-3.2-4.1c-1-1.3-0.5-1.7-0.5-1.7s4.5,0.3,5.1-0.7c0.5-1,0.7-4,0.7-4s2.6-2.7,1.9-3.8c-0.7-1.1-4.1-2-4.9-3.9c-0.8-1.8,1.7-3.1,1.8-4.7c0,0,7.5,0.7,10,2.7s1.4,5.9,3.9,5.9c2.5,0.1,6.4,5.2,8.2,5.2c1.8,0,6.9-1.8,6.9-1.8l0.7-8c0,0,4.4,7,5.6,6c1.3-1.1,7-9,9.1-10s-1.7-3.4-1.1-5.3c0.6-1.9,2.2-5.5,2.9-5.5c0.8,0,7.6-0.1,7.9-1.5c0.3-1.4,4.5-8.5,7.5-7.9c3,0.6,4,2.9,4.2,2C235.8,180.4,235.2,172.8,235.2,172.8z",
                    "salaj" : "M183.7,207.9c2.5,0.1,6.4,5.2,8.2,5.2s6.9-1.8,6.9-1.8l0.7-8c0,0,4.4,7,5.6,6c1.3-1.1,7-9,9.1-10c2-1.1-1.7-3.4-1.1-5.3c0.6-1.9,2.2-5.5,2.9-5.5s7.6-0.1,7.9-1.5s4.5-8.5,7.5-7.9c3,0.6,4,2.9,4.2,2c0.3-0.8-0.4-8.4-0.4-8.4c-0.8,0-2.7,0.9-4.2,1.2c-1.5,0.3-3.3-4.1-3.3-4.1s-1.6-3.7-4.8-1.6c-3.2,2.1-6-0.3-7.4-0.4c-1.3,0-5.4-0.7-7.2-1.5c-1.8-0.8-2-1.7-3.8-2.1c-1.8-0.4-3.9,0.1-5-1.1s-4.5-0.1-4.5-0.1c0.4,1-0.5,5.3-1.1,5.5c-0.6,0.2-1.7,2.4-2.8,1.6c-1.1-0.8-4.5-1.5-5.3-1.5c-0.8,0-3-1.8-3.5-3c-0.6-1.1-3.1-2.4-3.7-1.8c-0.6,0.6-1.7,0-1.7,0s-3,0.2-5.1,1.3s-5.9,3.7-7.4,3.7c1.5,2.3-2.2,3.5-2.5,4.5c-0.3,1-0.8,6.6-1.6,7.3c-0.8,0.7-2.4,1.6-2.5,3.1c0,1.5,0.6,1.1,2,1s1.4,5.6,1.4,6.4c0,0.8,0.7,3.1,3.8,2.6c3.2-0.5,4.9,3.9,4.9,5.4c0,0,7.5,0.7,10,2.7C182.3,204,181.2,207.9,183.7,207.9z"
                }
            },
        }
    });

    return Mapael;

}));