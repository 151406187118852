/* ========================================================================
* DOM-based Routing
* Based on http://goo.gl/EUTi53 by Paul Irish
*
* Only fires on body classes that match. If a body class contains a dash,
* replace the dash with an underscore when adding it to the object below.
*
* .noConflict()
* The routing is enclosed within an anonymous function so that you can
* always reference jQuery with $, even when in .noConflict() mode.
* ======================================================================== */

(function($) {
    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function() {

                // variable to hold data filters like year, domain
                this.dataFilters = [];
                // JavaScript to be fired on all pages
                $.philter({url:window.jsVars.wpThemeDirectoryUrl+'/assets/scripts'});

                this.initTimeline();

                this.doImagesLoading();

                this.cardsSliders();

                this.cardsFilters();

                this.partnersCards();

                this.autoSizeTextarea();

                this.modalCardSwitch();

            },
            finalize: function() {
                // browser window scroll (in pixels) after which the "back to top" link is shown
            var offset = 300,

            //browser window scroll (in pixels) after which the "back to top" link opacity is reduced
            offset_opacity = 1200,

            //duration of the top scrolling animation (in ms)
            scroll_top_duration = 700,

            //grab the "back to top" link
            $back_to_top = $('.cd-top');

            //hide or show the "back to top" link
            $(window).scroll(function () {
                $(this).scrollTop() > offset ? $back_to_top.addClass('cd-is-visible') : $back_to_top.removeClass('cd-is-visible cd-fade-out');
                if ($(this).scrollTop() > offset_opacity) {
                    $back_to_top.addClass('cd-fade-out');
                }
            });

            //smooth scroll to top
            $back_to_top.on('click', function (event) {
                event.preventDefault();
                $('body,html').animate({
                    scrollTop: 0
                }, scroll_top_duration);
            });
            },
            initTimeline: function() {
                var globalVars = this;
                var $reportsWrap = $( '.reports-slider-wrap' );
                var $reportsDates = $reportsWrap.find( '.reports-dates' );
                var reportYears = $reportsDates.find('.year-wrap');
                var totalSteps = $reportsWrap.data( 'reports' );
                var step = totalSteps;
                var offset = 1;
                var timelineTrigger = 0;

                reportYears = reportYears.get().reverse();
                $.each( reportYears, function( key, reportYear ){
                    holderWidth = parseFloat( $reportsDates.outerWidth() ) - parseInt( 40 ); // 40px year wrap width and 15px sldier offset
                    itemRight = ( 100 * parseInt( key ) / parseInt( step ) ) / 100 * parseFloat( holderWidth );
                    if ( key === 0 ) {
                        $(reportYear).css( 'right', itemRight + "px" );
                    } else {
                        itemRight = parseFloat( itemRight ) + parseInt( offset ) - parseInt( key );
                        $(reportYear).css( 'right', itemRight + "px" );
                    }
                });

                $(window).on( 'load resize', function(){
                    $.each( reportYears, function( key, reportYear ){
                        holderWidth = parseFloat( $reportsDates.outerWidth() ) - parseInt( 40 ); // 40px year wrap width and 15px sldier offset
                        itemRight = ( 100 * parseInt( key ) / parseInt( step ) ) / 100 * parseFloat( holderWidth );
                        if ( key === 0 ) {
                            $(reportYear).css( 'right', itemRight + "px" );
                        } else {
                            itemRight = parseFloat( itemRight ) + parseInt( offset ) - parseInt( key );
                            $(reportYear).css( 'right', itemRight + "px" );
                        }
                    });
                });

                globalVars.reportsDrag = new Dragdealer('reports-slider', {
                    x: 1,
                    left: 15,
                    right: 15,
                    steps: totalSteps + 1,
                    callback: function(x, y) {
                        var key = totalSteps - parseInt( totalSteps * x );
                        if ( globalVars.timelineTrigger === 0 ) {
                            $( reportYears[key] ).find('a').trigger('click');
                        }
                        globalVars.timelineTrigger = 0;
                    }
                });

                runMap( $reportsDates.find( '.year-wrap' ).last().find( 'a' ).html() );

                $reportsDates.find('a').on( 'click', function( event ) {

                    event.preventDefault();

                    var clickedYear = this;

                    globalVars.dataFilters.year = $( clickedYear ).data('report-target');
                    $( 'body' ).trigger( 'dataFiltersUpdate' );

                    globalVars.yearChange( clickedYear );

                });

                // trigger click on first year
                $( reportYears[0] ).find( 'a' ).trigger( 'click' );

            },
            cardContentModal: function( cardsListWrap ) {
                var $cardsListWrap = $( cardsListWrap );
                var sliderRows = $cardsListWrap.data('cards-rows');
                globalVars = this;
                $cardsListWrap.find( 'a[data-toggle="modal"]' ).click( function( e ) {

                    var modalData = cards[$( this ).data( 'card-id' )];
                    var $modalWrap = $( '.modals-wrap' );

                    var parentCard = $( this ).parents( '.card-wrap' ).first();
                    var totalActiveSlides = parentCard.parents( '.slick-active' ).first().parent().find( '.slick-active' ).length;
                    var totalSlides = parentCard.parents( '.slick-slide' ).last().data( 'slick-index' );
                    var itemsPerSlide = parentCard.parents( '.slick-track' ).find( '.slick-active' ).length;
                    var activeSlideIndex = parentCard.parents( '.slick-active' ).first().index( '.slick-active' );
                    var slickIndex = parentCard.parents( '.slick-active' ).first().data( 'slick-index' );

                    if ( typeof $cardsListWrap.data('cards-rows') === "undefined" || $cardsListWrap.data('cards-rows') == 1 ) {
                        slickIndex = parentCard.data('slick-index');
                        // handle prev
                        if ( slickIndex == 0 ) {
                            prev = parentCard.find( 'none' );
                            prev.goBack = 0;

                            nextSlideIndex = slickIndex + 1;
                            next = cardsListWrap.find( '.card-wrap[data-slick-index=' + nextSlideIndex + ']' );
                            next.parentKey = nextParentIndex;
                            next.slideIndex = nextSlideIndex;
                            next.goForward = nextSlideIndex;
                        } else {
                            prevSlideIndex = slickIndex - 1;
                            prev = cardsListWrap.find( '.card-wrap[data-slick-index=' + prevSlideIndex + ']' );
                            prev.goBack = prevSlideIndex;
                            prev.parentKey = prevSlideIndex;
                            prev.slideIndex = prevSlideIndex;

                            nextSlideIndex = slickIndex + 1;
                            next = cardsListWrap.find( '.card-wrap[data-slick-index=' + nextSlideIndex + ']' );
                            next.parentKey = nextSlideIndex;
                            next.slideIndex = nextSlideIndex;
                            next.goForward = nextSlideIndex;
                        }

                        var parentIndex = parentCard.data('slick-index');
                        if ( ( parentIndex + 1 ) % itemsPerSlide === 0 && parentIndex != 0 ) {
                            next.goForward = 1;
                        } else {
                            next.goForward = false;
                        }
                        if ( parentIndex % itemsPerSlide === 0 ) {
                            prev.goBack = 1;
                        } else {
                            prev.goBack = false;
                        }

                    } else {
                        var nextParentIndex;

                        var parentIndex = $( this ).parents( '.card-wrap' ).first().parent().data('parent-key');
                        var itemKey = $( this ).parents( '.card-wrap' ).first().parent().data('item-key');

                        var nextCard = {
                            parentIndex: parentIndex + 1,
                            itemKey: itemKey
                        }
                        var prevCard = {
                            parentIndex: parentIndex - 1,
                            itemKey: itemKey
                        }

                        // Handle Next Card
                        if ( ! $( '[data-parent-key='+ nextCard.parentIndex +'][data-item-key='+ nextCard.itemKey +']' ).parent().hasClass( 'slick-active' ) ) {
                            nextCard.itemKey = nextCard.itemKey + 1;
                            var nextSlide = 'false';
                            if ( nextCard.itemKey == 4 ) {
                                nextCard.itemKey = 0;
                                var nextSlide = 'true';
                            } else {
                                nextCard.parentIndex = $( '.slick-active' ).first().attr( 'data-slick-index' );
                            }
                        }
                        // Handle Prev Card
                        if ( ! $( '[data-parent-key='+ prevCard.parentIndex +'][data-item-key='+ prevCard.itemKey +']' ).parent().hasClass( 'slick-active' ) ) {
                            prevCard.itemKey = prevCard.itemKey - 1;
                            var prevSlide = 'false';
                            if ( prevCard.itemKey == -1 ) {
                                prevCard.itemKey = 3;
                                var prevSlide = 'true';
                            } else {
                                prevCard.parentIndex = $( '.slick-active' ).last().attr( 'data-slick-index' );
                            }
                        }

                    }

                    
                    var style_img = '';
                    if(modalData.image){
                        style_img = 'style="background-image:url(' + modalData.image + ');"';
                    }
                    if ( cardsListWrap.data('cards-rows') > 1 ) {
                        var dataCardList = cardsListWrap.index();
                        var dataCardMultipleRows = true;
                    } else {
                        var dataCardList = cardsListWrap.data('card-list');
                        var dataCardMultipleRows = false;
                    }
                    if ( typeof $cardsListWrap.data('cards-rows') === "undefined" || $cardsListWrap.data('cards-rows') == 1 ) {
                        var modal = '<div id="card-modal-' + $( this ).data( 'card-id' ) + '" prevslide="'+ prevSlide +'" nextslide="'+ nextSlide +'" class="single-card modal-data modal modal__large fade status-publish has-post-thumbnail hentry in " tabindex="-1" data-card-title="#' + modalData.sanitizeTitle + '">';
                    } else {
                        var modal = '<div id="card-modal-' + $( this ).data( 'card-id' ) + '" prevslide="'+ prevSlide +'" nextslide="'+ nextSlide +'" data-parent-key-prev="'+ prevCard.parentIndex +'" data-item-key-prev="'+ prevCard.itemKey +'" data-parent-key-next="'+ nextCard.parentIndex +'" data-item-key-next="'+ nextCard.itemKey +'" class="single-card modal-data modal modal__large fade status-publish has-post-thumbnail hentry in " tabindex="-1" data-card-title="#' + modalData.sanitizeTitle + '">';
                    }
                        modal += '<div class="modal-container">';
                          modal += '<div class="modal-content">';
                            modal += '<div class="panel panel-default">';
                              modal += '<button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>';
                              modal += '<div class="panel-heading" ' + style_img + '>';
                              modal += '<div class="bg-overlay"></div>';
                              modal += '<div class="container">';
                                modal += '<div class="row">';
                                  modal += '<div class="col-md-8 col-md-offset-2">';
                                    modal += '<div class="content">';
                                    modal += '<h1 class="entry-title">' + modalData.title + '</h1>';
                                    modal += '<div class="story__meta">';
                                    if ( modalData.foundation != null ) {
                                      modal += '<a href="' + modalData.foundationUrl + '">' + modalData.foundation + '</a>';
                                    }
                                    modal += '</div>';
                                    modal += '</div><!-- /.content -->';
                                  modal += '</div>';
                                modal += '</div><!-- /.row -->';
                              modal += '</div><!-- /.container -->';
                              modal += '</div>';
                              modal += '<div class="panel-body">';
                              modal += modalData.content;

                              modal += '<div class="stories-naviagtion">';
                              modal += '<div class="container-fluid">';
                              modal += '<div class="row vertical-align">';

                                if ( typeof $cardsListWrap.data('cards-rows') === "undefined" || $cardsListWrap.data('cards-rows') == 1 ) {
                                    modal += '<div class="col-md-6 left-card-nav">';
                                    modal += '<div class="card-nav-wrap js-go-back" data-card-multiple-rows="'+ dataCardMultipleRows +'" data-card-list="'+ dataCardList +'" data-go-back="'+ prev.goBack +'" data-target-parent="'+ prev.parentKey +'" data-target-index="'+ prev.slideIndex +'"><h4 class="card-nav-title">' + $( prev ).find( '.card-title' ).text() + '</h4><p class="card-nav-subtitle">' + prev.find( '.story-term' ).text() + '</p></div>';
                                    modal += '</div>';

                                    modal += '<div class="col-md-6 right-card-nav">';
                                    modal += '<div class="card-nav-wrap js-go-forward" data-card-multiple-rows="'+ dataCardMultipleRows +'" data-card-list="'+ dataCardList +'" data-go-forward="'+ next.goForward +'" data-target-parent="'+ next.parentKey +'" data-target-index="'+ next.slideIndex +'"><h4 class="card-nav-title">' + $( next ).find( '.card-title' ).text() + '</h4><p class="card-nav-subtitle">' + next.find( '.story-term' ).text() + '</p></div>';
                                    modal += '</div>';
                                } else {
                                    modal += '<div class="col-md-6 left-card-nav">';
                                    modal += '<div class="card-nav-wrap js-go-back" data-card-multiple-rows="'+ dataCardMultipleRows +'" data-card-list="'+ dataCardList +'"><h4 class="card-nav-title">' + $( $( '[data-parent-key='+ prevCard.parentIndex +'][data-item-key='+ prevCard.itemKey +']' )[0]).find( '.story-title' ).text() + '</h4><p class="card-nav-subtitle">' + $( $( '[data-parent-key='+ prevCard.parentIndex +'][data-item-key='+ prevCard.itemKey +']' )[0]).find( '.story-term' ).text() + '</p></div>';
                                    modal += '</div>';

                                    modal += '<div class="col-md-6 right-card-nav">';
                                    modal += '<div class="card-nav-wrap js-go-forward" data-card-multiple-rows="'+ dataCardMultipleRows +'" data-card-list="'+ dataCardList +'"><h4 class="card-nav-title">' + $( $( '[data-parent-key='+ nextCard.parentIndex +'][data-item-key='+ nextCard.itemKey +']' )[0]).find( '.story-title' ).text() + '</h4><p class="card-nav-subtitle">' + $( $( '[data-parent-key='+ nextCard.parentIndex +'][data-item-key='+ nextCard.itemKey +']' )[0]).find( '.story-term' ).text() + '</p></div>';
                                    modal += '</div>';
                                }

                              modal += '</div></div></div></div><!-- /.panel-body --></div></div></div></div>';

                      // if( $modalWrap.find( '#card-modal-' + $( this ).data( 'card-id' ) ).length < 1 ){
                        $('.modals-wrap').html('');
                        $('.modal-backdrop').remove();
                        $( $modalWrap ).append( modal );
                        $('body').trigger('modal-loaded');
                      // }
                });

                if ( window.location.href.includes('?card-modal') ) {
                    // var cardOpened = document.URL.substr( document.URL.indexOf('#') );
                    var cardOpened = ( window.location.href ).split('=').pop();
                    $( 'a[data-target=#card-modal-'+cardOpened+']' ).click();

                    var prevCardParentKey = $( '.modal-data' ).attr('data-parent-key-prev');
                    var prevCardItemKey = $( '.modal-data' ).attr('data-item-key-prev');
                    var nextCardParentKey = $( '.modal-data' ).attr('data-parent-key-next');
                    var nextCardItemKey = $( '.modal-data' ).attr('data-item-key-next');

                    var prevSlide = $( '.modal-data' ).attr('prevslide');
                    var nextSlide = $( '.modal-data' ).attr('nextslide');

                    // get prev/next modal data, close the current modal, change slide if needed and then open the target modal
                    $('.js-go-back').click( function(){
                        $backButton = $(this);
                        var $cardSliderWrap = $( $('.cards-list-wrap')[$backButton.data('card-list')] );
                        var $cardSlickSlider = $cardSliderWrap.find('slick-slider');
                        $backButton.parents('.single-card.modal').modal('toggle');
                        if ( !isNaN( parseInt( $backButton.data('go-back') ) ) || prevSlide == 'true' ) {
                            $cardSliderWrap.find('.slick-prev').click();
                        }
                        var dataCardMultipleRows = $backButton.data('card-multiple-rows');
                        setTimeout( function(){
                            if ( dataCardMultipleRows ) {
                                var cardToShow = $cardSliderWrap.find( '[data-parent-key=' + prevCardParentKey + '][data-item-key=' + prevCardItemKey + ']' ).find( '.js-modal-toggle' );
                            } else {
                                var cardToShow = $cardSliderWrap.find( '[data-slick-index=' + $backButton.data('target-index') + ']' ).find( '.js-modal-toggle' );
                            }
                            cardToShow.click();
                        }, 100 );
                    });
                    $('.js-go-forward').click( function(){
                        $forwardButton = $(this);
                        var $cardSliderWrap = $( $('.cards-list-wrap')[$forwardButton.data('card-list')] );
                        var $cardSlickSlider = $cardSliderWrap.find('slick-slider');
                        $forwardButton.parents('.single-card.modal').modal('toggle');
                        if ( !isNaN( parseInt( $(this).data('go-forward') ) ) || nextSlide == 'true' ) {
                            $cardSliderWrap.find('.slick-next').click();
                        }
                        var dataCardMultipleRows = $forwardButton.data('card-multiple-rows');
                        setTimeout( function(){
                            if ( dataCardMultipleRows ) {
                                var cardToShow = $cardSliderWrap.find( '[data-parent-key=' + nextCardParentKey + '][data-item-key=' + nextCardItemKey + ']' ).find( '.js-modal-toggle' );
                            } else {
                                var cardToShow = $cardSliderWrap.find( '[data-slick-index=' + $forwardButton.data('target-index') + ']' ).find( '.js-modal-toggle' );
                            }
                            cardToShow.click();
                        }, 100 );
                    });
                }
                
            },
            cptCards: function( $cardListSection ) {

                // var cardType = $cardListSection.data('cards-type');
                var globalVars = this;

                var promises = [];

                var cardsWrapper = $cardListSection.find('.cards-list');
                var $cardsWrapper = $( cardsWrapper );

                var sectionObjectType = $cardsWrapper.data('object');

                var sectionCardType = $cardsWrapper.data('card-type');

                var cardsCount = cards.length;
                var cardsCounter = 0;
                var sliderDivStatus = 0;

                $cardsWrapper.empty();

                $.each( cards, function( index, card ) {
                    if (
                            ( (
                                typeof sectionCardType !== "undefined"
                                && sectionCardType.toLowerCase().indexOf( card.cardType.toLowerCase() ) > -1
                            )
                            || (
                                typeof sectionCardType === "undefined"
                            ) )

                        && sectionObjectType == card.objectType

                        && globalVars.activeCardFilters( $cardListSection, card )
                        ) {
                        var style_img = '';
                        if( card.image ) {
                            style_img = 'style="background-image:url(' + card.image + ');"';
                        }
                        var cardTemplate = '';
                        cardTemplate += '<div class="col-sm-3 card-wrap" data-object-type=" ' + card.objectType + ' ">';
                        cardTemplate += '<div class="image-card ' + card.objectType + '-card story-modal__trigger" data-philter-grayscale="100 0" data-philter-drop-shadow="0 0 3 30 0 10 6 30" data-start-date="' + card.dateBegin + '" data-end-date="' + card.dateEnd + '" data-card-programs="' + card.cardPrograms + '" data-card-foundation="' + card.foundationSlug + '" data-card-domain="' + card.cardDomains + '">';
                        cardTemplate += '<a data-location="' + card.url + '" data-href="' + card.permalink + '" class="name js-modal-toggle" data-toggle="modal" data-target="#card-modal-' + card.id + '" data-card-id="' + card.id + '">';
                        cardTemplate += '<div class="bg-overlay"></div>';
                        cardTemplate += '<div class="media-cover" data-cover-bg="' + card.image + '" ' + style_img + '></div>';
                        cardTemplate += '<div class="media-content">';
                        cardTemplate += '<h2 class="story-title">' + card.title + '</h2>';
                        if ( typeof card.foundation !== 'undefined' && card.foundation !== null ) {
                            cardTemplate += '<p class="story-term">' + card.foundation + '</p>';
                        }
                        cardTemplate += '</div>';
                        cardTemplate += '<div class="card-title" style="display: none;">' + card.title + '</div></a></div></div>';

                        promises.push( $( cardsWrapper ).prepend( cardTemplate ) );
                    }
                });

                $.when.apply($, promises).then(function() {
                    $cardListSection.trigger( 'cardsLoaded' );
                });

            },
            activeCardFilters: function( $cardListSection, card ) {
                var globalVars = this;
                var yearFilter = true;
                var programFilter = true;
                var domainFilter = true;
                var foundationFilter = true;

                if ( !$cardListSection.hasClass('cards-filterable-wrap') ) {
                    return true;
                }

                if ( parseInt( globalVars.dataFilters.year ) > 0 && ( card.dateBegin > globalVars.dataFilters.year || card.dateEnd < globalVars.dataFilters.year ) ) {
                    yearFilter = false;
                }

                if (
                        typeof globalVars.dataFilters.program !== "undefined" &&
                        globalVars.dataFilters.program.length > 0 &&

                        (
                            globalVars.dataFilters.program.indexOf( card.cardPrograms.replace(/\s+/g, '-').toLowerCase() ) < 0 ||
                            card.cardPrograms.length < 1
                        )

                    ) {
                    programFilter = false;
                }

                if (
                        typeof globalVars.dataFilters.domain !== "undefined" &&
                        globalVars.dataFilters.domain.length > 0 &&
                        (
                            card.cardDomains.replace(/\s+/g, '-').toLowerCase().indexOf( globalVars.dataFilters.domain ) < 0 ||
                            card.cardDomains.length < 1
                        )
                ) {
                    domainFilter = false;
                }

                if (
                        typeof globalVars.dataFilters.foundation !== "undefined" &&
                        globalVars.dataFilters.foundation.length > 0 &&
                        (
                            globalVars.dataFilters.foundation.indexOf( card.foundationSlug ) < 0 ||
                            card.foundationSlug.length < 1
                        )
                    ) {
                    foundationFilter = false;
                }

                if ( yearFilter && programFilter && domainFilter && foundationFilter ) {
                    return true;
                }
                return false;
            },
            cardsSliders: function() {

                var globalVars = this;
                // on page load read all cards sections and then watch for events on them
                var $cardListSections = $('.cards-list-wrap');

                $cardListSections.on( 'cardsLoaded', function() {

                    $currentCardListSection = $( this );
                    if ( $currentCardListSection.find('.card-wrap').length > 0 ) {
                        cardsListWrap = $currentCardListSection.find( '.cards-list' );
                        var sliderRows = cardsListWrap.data('cards-rows');
                        var sliderCols = cardsListWrap.data('cards-cols');
                        if ( typeof sliderRows === "undefined" || sliderRows === 1 ) {
                            sliderRows = 1;
                            $( cardsListWrap ).slick({
                                infinite: false,
                                rows: sliderRows,
                                slidesToShow: 4,
                                slidesToScroll: 4,
                                responsive: [
                                {
                                  breakpoint: 768,
                                  settings: {
                                    slidesToShow: 1,
                                    slidesToScroll: 1,
                                    rows: sliderRows,
                                    
                                  }
                                },
                                {
                                  breakpoint: 1200,
                                  settings: {
                                    slidesToShow: 2,
                                    slidesToScroll: 2,
                                    rows: sliderRows,
                                    
                                  }
                                }]
                            }).promise().done(function() {
                                // everything slick-related set
                                //if rows > 1 then rearrange cards from left to right
                                if ( sliderRows > 1 ) {
                                    var slides = $currentCardListSection.find('.slick-slide');
                                    // avem 4 slick-slide elements pe display
                                    slides.each( function(slideKey, slide) {
                                        $cardsWrap = $(slide).children('div');
                                        $cardsWrap.each( function( cardWrapKey, cardWrap ) {
                                            var $cardWrap = $(cardWrap);
                                            $cardWrap.attr('data-parent-key', slideKey);
                                            $cardWrap.attr('data-item-key', cardWrapKey);
                                        });
                                    });
                                }
                                // display the section
                                $currentCardListSection.show();
                                globalVars.cardContentModal( cardsListWrap );
                            });
                        } 
                        if ( sliderCols === 4 ) {
                            $( cardsListWrap ).slick({
                                infinite: false,
                                rows: sliderRows*4,
                                slidesToShow: 1,
                                slidesToScroll: 1,
                            }).promise().done(function() {
                                // everything slick-related set
                                //if rows > 1 then rearrange cards from left to right
                                if ( sliderRows > 1 ) {
                                    var slides = $currentCardListSection.find('.slick-slide');
                                    // avem 4 slick-slide elements pe display
                                    slides.each( function(slideKey, slide) {
                                        $cardsWrap = $(slide).children('div');
                                        $cardsWrap.each( function( cardWrapKey, cardWrap ) {
                                            var $cardWrap = $(cardWrap);
                                            $cardWrap.attr('data-parent-key', slideKey);
                                            $cardWrap.attr('data-item-key', cardWrapKey);
                                        });
                                    });
                                }
                                // display the section
                                $currentCardListSection.show();
                                globalVars.cardContentModal( cardsListWrap );
                            });
                        } else if ( sliderCols === 2 ) {
                            $( cardsListWrap ).slick({
                                infinite: false,
                                rows: sliderRows,
                                slidesToShow: 2,
                                slidesToScroll: 2,
                            }).promise().done(function() {
                                // everything slick-related set
                                //if rows > 1 then rearrange cards from left to right
                                if ( sliderRows > 1 ) {
                                    var slides = $currentCardListSection.find('.slick-slide');
                                    // avem 4 slick-slide elements pe display
                                    slides.each( function(slideKey, slide) {
                                        $cardsWrap = $(slide).children('div');
                                        $cardsWrap.each( function( cardWrapKey, cardWrap ) {
                                            var $cardWrap = $(cardWrap);
                                            $cardWrap.attr('data-parent-key', slideKey);
                                            $cardWrap.attr('data-item-key', cardWrapKey);
                                        });
                                    });
                                }
                                // display the section
                                $currentCardListSection.show();
                                globalVars.cardContentModal( cardsListWrap );
                            });
                        } else if ( sliderCols === 1 ) {
                            $( cardsListWrap ).slick({
                                infinite: false,
                                rows: sliderRows,
                                slidesToShow: 1,
                                slidesToScroll: 1,
                            }).promise().done(function() {
                                // everything slick-related set
                                //if rows > 1 then rearrange cards from left to right
                                if ( sliderRows > 1 ) {
                                    var slides = $currentCardListSection.find('.slick-slide');
                                    // avem 4 slick-slide elements pe display
                                    slides.each( function(slideKey, slide) {
                                        $cardsWrap = $(slide).children('div');
                                        $cardsWrap.each( function( cardWrapKey, cardWrap ) {
                                            var $cardWrap = $(cardWrap);
                                            $cardWrap.attr('data-parent-key', slideKey);
                                            $cardWrap.attr('data-item-key', cardWrapKey);
                                        });
                                    });
                                }
                                // display the section
                                $currentCardListSection.show();
                                globalVars.cardContentModal( cardsListWrap );
                            });
                        }
                    }
                });

                $cardListSections.each( function( key, cardListSection ){
                    globalVars.cptCards( $( cardListSection ) );
                });

                $( 'body' ).on('dataFiltersUpdate', function() {
                    $cardListSections.each( function( key, cardListSection ){
                        $cardListSection = $( cardListSection );
                        currentCardList = $cardListSection.find('.cards-list');
                        $currentCardList = $( currentCardList );
                        if ( $currentCardList.find('.card-wrap').length > 0 ) {
                            $currentCardList.slick( 'unslick' );
                        }
                        $currentCardList.empty();
                        globalVars.cptCards( $( cardListSection ) );
                    });
                });

            },
            yearChange: function( clickedYear ) {
                var globalVars = this;
                var $reportsWrap = $( '.reports-slider-wrap' );
                var $reportsDates = $reportsWrap.find( '.reports-dates' );
                var reportYears = $reportsDates.find('.year-wrap');

                var $reportsYear = $( '.report-year' );
                var $storiesYear = $( '.story-year-wrap' );

                $reportsDates.find('a').removeClass('selected');

                $(clickedYear).addClass('selected');

                var selectedReportYearId = "#report-year-" + $( clickedYear ).data( 'report-target' );
                var selectedStoryYearId = "#story-year-" + $( clickedYear ).data( 'report-target' );

                var $cardsFilters = $('.filters-wrapper');
                if ( $cardsFilters.length > 0 ) {
                    var $filterYear = $cardsFilters.find('.filter-year');
                    if ( isNaN( parseInt( $( clickedYear ).data('report-target') ) ) ) {
                        $(".filter-year option[value='']").attr('selected', true);
                    } else {
                        $filterYear.val( $( clickedYear ).data('report-target') );
                    }
                }
                runMap( $( clickedYear ).html() );

                $reportsYear.removeClass('selected');
                $storiesYear.hide();

                $selectedReportYearId = $( selectedReportYearId );
                $selectedStoryYearId = $( selectedStoryYearId );

                $selectedReportYearId.addClass('selected');
                $selectedReportYearId.trigger('cssClassChanged');
                $selectedStoryYearId.show();

                // move the slider to the year
                var yearOffset = parseInt( $reportsWrap.data( 'reports' ) ) - reportYears.length;
                var yearStep = $( clickedYear ).data('year-count') + yearOffset + 2;
                if ( yearStep ) {
                    globalVars.timelineTrigger = 1;
                    globalVars.reportsDrag.setStep( yearStep );
                }

                // globalVars.cardsSlider( $( clickedYear ).data( 'report-target' ) );
            },

            autoSizeTextarea: function() {
                $('.auto-size-textarea').textareaAutoSize();
            },

            doImagesLoading : function() {
                var $mediaCovers = $('.media-cover').not('.js-image-loaded');
                if ( $mediaCovers ) {
                    $mediaCovers.each( function( mediaCover ) {
                        $mediaCover = $( mediaCover );
                        if ( $mediaCover.data('cover-bg') ) {
                            $mediaCover.css( 'background-image', $mediaCover.data('cover-bg') );
                        }
                        $mediaCover.addClass( 'js-image-loaded' );
                    });
                }
            },
            getObjectList: function( objectType, offset ) {
                var data = {
                    action: 'arc_object_list',
                    objectType: objectType,
                    offset: offset,
                }
                return $.ajax({
                  url: wptConditional.ajaxurl,
                  type: "post",
                  data: data
                });
            },
            cardsFilters: function() {
                // dropdawn actions on stories and programs section for now
                var globalVars = this;

                var $filters = $( '.card-filter' );
                $filters.each( function( index, filter ){
                    $filter = $( filter );
                    $filter.change( function(){
                        globalVars.dataFilters[ $(this).data('filtered-data') ] = $(this).val();
                        if ( $(this).hasClass('filter-year') ) {
                            var $reportDates = $('#dates');
                            if ( $reportDates.length > 0 ) {
                                if ( $(this).val().length < 3 ) {
                                    $reportDates.find('.timeline-year-link[href="#total"]').click();
                                } else {
                                    $currentDate = $reportDates.find( '.timeline-year-link[href="#' + $(this).val() + '"]' );
                                    if ( $currentDate.length > 0 ) {
                                        $currentDate.click();
                                    }
                                }
                            }
                        }
                        $( 'body' ).trigger( 'dataFiltersUpdate' );
                    });
                });
                $( '.timeline-year-link' ).click(function(){
                    if ( $( '.homepage__stories' ).find( '.cards-list' ).is(':empty') ) {
                        $( '.homepage__stories' ).find( '.section-title' ).hide();
                        $( '.homepage__stories' ).find( '.text-center' ).hide();
                    } else {
                        $( '.homepage__stories' ).find( '.section-title' ).show();
                        $( '.homepage__stories' ).find( '.text-center' ).show();
                    }
                });
                $( '.timeline-year-link' ).click(function(){
                    if ( $( '.homepage__programs' ).find( '.cards-list' ).is(':empty') ) {
                        $( '.homepage__programs' ).find( '.section-title' ).hide();
                        $( '.homepage__programs' ).find( '.text-center' ).hide();
                    } else {
                        $( '.homepage__programs' ).find( '.section-title' ).show();
                        $( '.homepage__programs' ).find( '.text-center' ).show();
                    }
                });
                $( '.timeline-year-link' ).click(function(){
                    if ( $( '.homepage__testimonials' ).find( '.cards-list' ).is(':empty') ) {
                        $( '.homepage__testimonials' ).find( '.section-title' ).hide();
                        $( '.homepage__testimonials' ).find( '.text-center' ).hide();
                    } else {
                        $( '.homepage__testimonials' ).find( '.section-title' ).show();
                        $( '.homepage__testimonials' ).find( '.text-center' ).show();
                    }
                });
            },

            modalCardSwitch: function() {
                $('body').on('modal-loaded', function() {
                    var prevCardParentKey = $( '.modal-data' ).attr('data-parent-key-prev');
                    var prevCardItemKey = $( '.modal-data' ).attr('data-item-key-prev');
                    var nextCardParentKey = $( '.modal-data' ).attr('data-parent-key-next');
                    var nextCardItemKey = $( '.modal-data' ).attr('data-item-key-next');

                    var prevSlide = $( '.modal-data' ).attr('prevslide');
                    var nextSlide = $( '.modal-data' ).attr('nextslide');

                    // get prev/next modal data, close the current modal, change slide if needed and then open the target modal
                    $('.js-go-back').click( function(){
                        $backButton = $(this);
                        var $cardSliderWrap = $( $('.cards-list-wrap')[$backButton.data('card-list')] );
                        var $cardSlickSlider = $cardSliderWrap.find('slick-slider');
                        $backButton.parents('.single-card.modal').modal('toggle');
                        if ( !isNaN( parseInt( $backButton.data('go-back') ) ) || prevSlide == 'true' ) {
                            $cardSliderWrap.find('.slick-prev').click();
                        }
                        var dataCardMultipleRows = $backButton.data('card-multiple-rows');
                        setTimeout( function(){
                            if ( dataCardMultipleRows ) {
                                var cardToShow = $cardSliderWrap.find( '[data-parent-key=' + prevCardParentKey + '][data-item-key=' + prevCardItemKey + ']' ).find( '.js-modal-toggle' );
                            } else {
                                var cardToShow = $cardSliderWrap.find( '[data-slick-index=' + $backButton.data('target-index') + ']' ).find( '.js-modal-toggle' );
                            }
                            cardToShow.click();
                        }, 100 );
                    });
                    $('.js-go-forward').click( function(){
                        $forwardButton = $(this);
                        var $cardSliderWrap = $( $('.cards-list-wrap')[$forwardButton.data('card-list')] );
                        var $cardSlickSlider = $cardSliderWrap.find('slick-slider');
                        $forwardButton.parents('.single-card.modal').modal('toggle');
                        if ( !isNaN( parseInt( $(this).data('go-forward') ) ) || nextSlide == 'true' ) {
                            $cardSliderWrap.find('.slick-next').click();
                        }
                        var dataCardMultipleRows = $forwardButton.data('card-multiple-rows');
                        setTimeout( function(){
                            if ( dataCardMultipleRows ) {
                                var cardToShow = $cardSliderWrap.find( '[data-parent-key=' + nextCardParentKey + '][data-item-key=' + nextCardItemKey + ']' ).find( '.js-modal-toggle' );
                            } else {
                                var cardToShow = $cardSliderWrap.find( '[data-slick-index=' + $forwardButton.data('target-index') + ']' ).find( '.js-modal-toggle' );
                            }
                            cardToShow.click();
                        }, 100 );
                    });
                });
            },
            partnersCards: function() {
                var $partnersCards = $('.partners-cards-wrap');
                if ( $partnersCards ) {
                    $partnersCards.slick({
                        infinite: false,
                        rows: 2,
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        responsive: [
                        {
                          breakpoint: 768,
                          settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                          }
                        }]
                    });
                }
            }

        },
        // Home page
        'home': {
            init: function() {
                // JavaScript to be fired on the home page
            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function() {
                // JavaScript to be fired on the about us page
            }
        },
        'archive': {
            postFilter: function() {
                //show/hide post filters
                $postFilter = $('.filter-toggler .toggle-button');
                $filterFormWrapper = $('.post-grid__filters');
                $postFilter.on('click', function() {
                    $filterFormWrapper.toggle();
                });
            },
            openActiveCard: function() {
                if ( location.hash.length > 0 ) {
                    $initialCard = $( '*[data-card-title="' + location.hash + '"]' );
                    if ( typeof $initialCard != 'undefined' ) {
                        $initialCard.modal('show');
                    }
                }
            },
            init: function() {
                // JavaScript to be fired on the archive pages

                this.postFilter();

                this.openActiveCard();

            }
        }
    };

        var displayAreas = {};

    var runMap = function( reportYear ) {

        var selectedAttribute = {
            attrs: {
                stroke: "#fff",
                "stroke-width": 2,
                'stroke-opacity': 1,
                'opacity': 1,
            }
        };

        var tooltip = "";

        var plots = {}, point = null;

        fundatiiPinsYear = [];

        for ( var fundatiePin in fundatiiPins ) {
            if ( fundatiePin <= reportYear ) {
                for ( var county in fundatiiPins[ fundatiePin ] ) {
                    fundatiiPinsYear.push( fundatiiPins[ fundatiePin ][ county ] );
                }

            }
        }

        if ( typeof fundatiiPinsYear != 'undefined' ) {
            for ( var i=0; i < fundatiiPinsYear.length; i++ ) {

                pinFundatie = fundatiiPinsYear[i];

                if ( Object.keys( pinFundatie.rapoarte ).length > 0 ) {
                    tooltip = "";
                    tooltip = {
                        content: '<img class="tooltip-logo" src="' + pinFundatie.logo_url + '" height="160" />',
                        offset: {
                            top: -200
                        }
                    };
                    plots[ pinFundatie.title ] = {
                        type: "image",
                        url: window.jsVars.wpThemeDirectoryUrl + "/dist/images/map_marker.png",
                        width: 15,
                        height: 21,
                        latitude: parseFloat( pinFundatie.lat ),
                        longitude: parseFloat( pinFundatie.lng ),
                        attrs: {
                            opacity: 1
                        },
                        attrsHover: {
                            transform: "s1.5"
                        },
                        tooltip: tooltip,
                        href: pinFundatie.url
                    };

                } else {
                    plots[ pinFundatie.title ] = {
                        type: "image",
                        url: window.jsVars.wpThemeDirectoryUrl + "/dist/images/map_marker.png",
                        width: 20,
                        height: 28,
                        latitude: parseFloat( pinFundatie.lat ),
                        longitude: parseFloat( pinFundatie.lng ),
                        attrs: {
                            opacity: 1
                        },
                        attrsHover: {
                            transform: "s1.5"
                        },
                        href: pinFundatie.url
                    };
                }


            }
        }

        var currentAreas = function() {
            return new Promise(function(resolve, reject) {
                var displayAreas = {};
                for ( var fundatiePin in fundatiiPins ) {
                    if ( fundatiePin <= reportYear ) {
                        for ( var countyData in fundatiiPins[ fundatiePin ] ) {
                            displayAreas[ fundatiiPins[ fundatiePin ][ countyData ]['county'] ] = selectedAttribute;
                        }
                    }
                }
                resolve(displayAreas);
            });
        };

        var reDrowMap = function() {
            var fillMapColor = "rgba(95,191,200,0.8)";
            if ( $( 'body' ).hasClass( 'magenta' ) ) {
                fillMapColor = "#DB2953";
            }

            currentAreas().then( function( displayAreas ) {
                $(".mapcontainer").mapael({
                    map : {
                        // Set the name of the map to display
                        name : "romania",
                        defaultArea : {
                            attrs : {
                                fill: fillMapColor,
                                stroke: "#fff",
                                "stroke-width": 0.7,
                                opacity : 0.6,
                                'fill-opacity': 0
                            }
                        },

                    },
                    plots: plots,
                    areas: displayAreas,
                });
            });

        };
        reDrowMap();

        $(window).on( 'resize', function(){
            reDrowMap();
        });

    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };
    // Load Events
    $(document).ready(UTIL.loadEvents);

    // TODO 

    $(window).load(function(){

        $( "#slick_image_slider" ).slick({
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
        });

        var my_plots_content = {};
        var my_plots_2 = {};
        var my_display_areas_2 = {};

        for(var j = 0; j < fundatii_data.length; j++) {

            my_plots_content[fundatii_data[j].titlu] = {
                image: fundatii_data[j].logo,
                title: 'Trimite un SMS la numărul <span class="magenta_text">8837</span> cu codul <span class="magenta_text">' + fundatii_data[j].cod + '</span>.',
                desc: fundatii_data[j].desc
            };

            my_plots_2[fundatii_data[j].titlu] = {
                type: "image",
                url: "https://fundatiicomunitare.ro/wp-content/themes/fundatiicomunitare/dist/images/map_marker.png",
                width: 15,
                height: 21,
                latitude: fundatii_data[j].lat,
                longitude: fundatii_data[j].lng,
                attrs: {opacity: 1},
                attrsHover: {transform: "s1.5"},
                eventHandlers: {
                    click: function (e, id, mapElem, textElem) {
                        e.preventDefault();
                        $('#map_judet_logo').attr('src', my_plots_content[id].image);
                        $('#map_judet_title').html(my_plots_content[id].title);
                        $('#map_judet_desc').html(my_plots_content[id].desc);
                        $('#map_judet').show();
                        $('#map_slogan').hide();
                    },
                }
            };

            my_display_areas_2[fundatii_data[j].judet] = {
                attrs: {
                    stroke: "#424242",
                    "stroke-width": 2,
                    "stroke-opacity": 1,
                    opacity: 1
                },
                eventHandlers: {
                    click: function (e, id, mapElem, textElem) {
                        e.preventDefault();
                    },
                }
            };
        }
    
        $(".mapcontainer_static").mapael({
            map : {
                // Set the name of the map to display
                name : "romania",
                defaultArea : {
                    attrs : {
                        fill: "#5fbfc8",
                        stroke: "#888",
                        "stroke-width": 0.7,
                        opacity : 0.6,
                        'fill-opacity': 0
                    },
                    eventHandlers: {
                        click: function (e, id, mapElem, textElem) {
                            e.preventDefault();
                            $('#map_judet').hide();
                            $('#map_slogan').show();
                        }
                    }
                },
    
            },
            plots: my_plots_2,
            areas: my_display_areas_2,
        });
    })

})(jQuery);